import { createCpmComponentDefinition, type StructuredAsset } from '@dx-ui/cpm-sdk';
import { useSegmentedItems } from '../../hooks/use-segments';
import { PatchworkGrid, type PatchworkGridType } from '@dx-ui/patchwork-grid';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';

type ImageObjectTypes = {
  aspectRatios: {
    [key: string]: {
      url: string;
    };
  };
  altText?: string;
  caption?: string;
  captionLink?: string;
};

const createImageObject = (image: ImageObjectTypes) => {
  const imageObject = {
    url: image?.aspectRatios['1x1']?.url ?? image?.aspectRatios['3x2']?.url ?? '',
    variants: {
      '2x1': image?.aspectRatios?.['2x1']?.url ?? image?.aspectRatios['16x9']?.url ?? '',
    },
    altText: image?.altText ?? '',
    captionData: image?.caption
      ? {
          caption: image?.caption,
          captionLink: image?.captionLink ?? '',
        }
      : undefined,
  };

  return imageObject;
};

export default createCpmComponentDefinition(
  'Patchwork Grid',

  function mapComponentData({ data, componentParams }) {
    const images = data.cpmAssets;

    const rows: PatchworkGridType['rows'] = [
      {
        headline: data?.headline ?? '',
        description: data?.longDescription ?? '',
        link: {
          label: data?.link?.label ?? '',
          url: data?.link?.url ?? '',
          isNewWindow: data?.link?.isNewWindow ?? false,
          adaDescription: data?.link?.adaDescription ?? '',
        },
        // The first row only gets a single image
        // min 1 / max 1 image for row 1
        images: images.length > 0 && images[0] ? [createImageObject(images[0])] : [],
      },
    ];

    if (images.length > 1) {
      // If more than one image is supplied in the doc, these fall onto the second row.
      // min 2  / max 3 images for row 2
      const additionalImages = images
        .slice(1, 4)
        .filter((image): image is StructuredAsset => image !== undefined)
        .map(createImageObject);

      rows.push({
        images: additionalImages,
      });
    }

    return {
      $ref: data.ref?.$ref,
      id: data.id,
      segmentIds: data.segmentIds,
      isReverse: componentParams?.display === 'mirror' || false,
      brandComponentTheme: componentParams?.theme,
      rows,
    };
  },

  function PatchworkGridCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items);

    if (!filteredItems.length) {
      return null;
    }

    const isWA = brandCode === 'WA';

    const data = filteredItems[0];

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <PatchworkGrid
          key={data.id}
          isReverse={data?.isReverse}
          brandComponentTheme={data?.brandComponentTheme}
          rows={data.rows}
          isAnimated={isWA}
        />
      </BrandComponentThemeInline>
    );
  }
);
