import type { OscDomLink, CpmNavigationList, CpmBrandNavigationList } from '@dx-ui/cpm-sdk';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { Header as DxUiHeader, type UserLinks, type TDrawerItem } from '@dx-ui/osc-header';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import type { LoginResponse } from '@dx-ui/osc-login';
import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import {
  BrandComponentThemeInline,
  useBrandData,
  useEnvironmentVariables,
  applyConductricsNHCBP5615,
  hiltonGetaways,
  Goals,
} from '@dx-ui/cpm-mapping-shared';
import { useGetawaysNavABTest } from '../../hooks/use-getaways-nav-abtest';
import { goUserTypes, getBaseUrl } from '../../utils/constants';
import { useRouter } from 'next/router';
import { useProgramMember } from '../../utils/useProgramMember';
import { useTranslation } from 'next-i18next';
import { sendReward } from '@dx-ui/framework-conductrics';

type GuestInfo = ReturnType<typeof useAuth>['guestInfo'];

const replaceLang = (stringValue: string, lang: string) => stringValue?.replace('__LANG__', lang);

const getHonorsSignInUrl = (env: EnvironmentVariables, lang: string) =>
  replaceLang(env.DX_AUTH_UI ?? '', lang);
const getHonorsJoinUrl = (env: EnvironmentVariables, lang: string) =>
  replaceLang(env.HONORS_JOIN_URL ?? '', lang);
const getForgotInfoUrl = (env: EnvironmentVariables, lang: string) =>
  replaceLang(env.PARTNER_FORGET_INFO_LINK ?? '', lang);
const getGlobalPrivacyUrl = (env: EnvironmentVariables) => env.GLOBAL_PRIVACY_URL ?? '';
const getHonorsAccountUrl = (env: EnvironmentVariables, lang: string) =>
  getBaseUrl(env, `${lang}/hilton-honors/guest/my-account/`);

function getUserFromGuestInfo(guestInfo: GuestInfo) {
  if (!guestInfo) {
    return undefined;
  }

  const hHonorsSummary = guestInfo?.hhonors?.summary;
  const hHonorsNumber = guestInfo?.hhonors?.hhonorsNumber;
  const hHonorsProgramAccountSummary = guestInfo?.hhonors?.programAccountSummary;
  const hHonorsIsHGVMax = Boolean(guestInfo?.hhonors?.isOwnerHGVNew);
  const hHonorsIsTeamMember = Boolean(guestInfo?.hhonors?.isTeamMember);

  return {
    name: getGuestFirstName({ guestInfo }),
    honorsTier: hHonorsSummary?.tierName || '',
    honorsPoints: hHonorsSummary?.totalPoints || 0,
    hhonorsNumber: hHonorsNumber || '',
    honorsPointsFmt: hHonorsSummary?.totalPointsFmt || '',
    isHGVMax: hHonorsIsHGVMax || false,
    isTeamMember: hHonorsIsTeamMember || false,
    honorsPrograms: hHonorsProgramAccountSummary || [],
  };
}

function useDxUiHeaderData(
  guestInfo: GuestInfo,
  brandCode: string,
  languageCode: string,
  formalBrandName = 'Hilton',
  brandUrl = '/',
  isMegaNav: boolean,
  env: EnvironmentVariables,
  isSMBMember = false
) {
  const { t } = useTranslation('osc-header');
  const brandName = brandCode === 'WW' ? 'Hilton For the Stay' : formalBrandName; // `Hilton For The Stay` text is only for portfolio pages

  const brand = {
    code: brandCode,
    name: brandName,
    url: brandUrl,
  };

  const user = getUserFromGuestInfo(guestInfo);

  const hgvLink = {
    label: t('hgvMaxRateDetails'),
    url: `https://help.hilton.com/s/article/HGV-Max-rate`,
  };

  const smbMemberLink = {
    label: t('manageHiltonForBusiness'),
    url: `${env.OHW_BASE_URL}${languageCode}/business/manage/`,
  };

  const goHiltonLink = {
    label: t('goHiltonProgramDetails'),
    url: `https://help.hilton.com/s/article/What-is-the-Go-Hilton-employee-program`,
  };

  const manageFriendsAndFamilyLink = {
    label: t('manageFriendsAndFamily'),
    url: `https://tmtp.hilton.com/tmtp/main.html`,
  };

  const userLinks: UserLinks = {
    signInLink: { url: getHonorsSignInUrl(env, languageCode) },
    signUpLink: { url: getHonorsJoinUrl(env, languageCode) },
    accountLink: { url: getHonorsAccountUrl(env, languageCode) },
    additionalLinks: isMegaNav
      ? [
          {
            label: t('activity'),
            url: `${env.OHW_BASE_URL}${languageCode}/hilton-honors/guest/activity/`,
          },
          {
            label: t('pointsLabel'),
            url: `${env.OHW_BASE_URL}${languageCode}/hilton-honors/guest/points/`,
          },
          {
            label: t('profile'),
            url: `${env.OHW_BASE_URL}${languageCode}/hilton-honors/guest/profile/`,
          },
          ...(user?.isTeamMember ? [goHiltonLink, manageFriendsAndFamilyLink] : []),
          ...(user?.isHGVMax ? [hgvLink] : []),
          ...(isSMBMember ? [smbMemberLink] : []),
        ]
      : undefined,
  };

  const loginOptions = {
    options: {
      showCreatePassword: true,
      forgetInfoLink: { url: getForgotInfoUrl(env, languageCode) },
      secureInfoLink: { url: getGlobalPrivacyUrl(env) },
      joinLink: { url: getHonorsJoinUrl(env, languageCode), underline: false },
    },
  };

  return {
    brand,
    user,
    userLinks,
    loginOptions,
  };
}

export default createCpmComponentDefinition(
  'Header',

  function mapData({ data }) {
    if (isBrandNavigationLinks(data?.items)) {
      const megaNavLinks = data.items
        ?.filter((item) => !!item.primaryNav)
        .map((navItem) => {
          const primary = navItem.primaryNav;
          const item: TDrawerItem = mapMegaNavLink(primary);
          const secondary = navItem.secondaryNavItems;

          if (secondary && secondary) {
            item.subMenu = secondary.map((navItem) => mapMegaNavLink(navItem));
          }

          return item;
        });

      return {
        megaNavLinks,
        type: 'BrandNavigationList',
      };
    }

    if (isNavigationLinks(data?.items)) {
      return {
        mainNavLinks: data?.items?.[0].links || [],
        type: 'NavigationList',
      };
    }

    return {
      type: 'Unmapped',
    };
  },

  function Header({
    navigationList: listData,
    componentParams,
    mappedPage: { hidePageHeader: hideHeader, brandCode, languageCode },
    metrics,
  }) {
    const env = useEnvironmentVariables();
    const brandData = useBrandData();
    const router = useRouter();
    const isPortfolioHomePage = router.asPath === '/';
    const showLanguageSelector = isPortfolioHomePage;

    const { login, logout, guestInfo, isLoading } = useAuth();
    const [trackSignIn, setTrackSignIn] = useState(false);

    const member = useProgramMember({
      programAccountId: guestInfo?.hhonors?.programAccountSummary?.[0]?.accountId,
    });

    const isSMBMember = Boolean(
      member?.role && ['admin', 'owner', 'employee'].includes(member.role)
    );

    const { brand, user, userLinks, loginOptions } = useDxUiHeaderData(
      guestInfo,
      brandCode,
      languageCode,
      brandData?.formalName ?? undefined,
      brandData?.url ?? undefined,
      !!listData?.megaNavLinks,
      env,
      isSMBMember
    );

    const { shouldDisplayUnderResorts } = useGetawaysNavABTest();

    const theme = ['GU', 'ND'].includes(brandCode) ? 'dark' : undefined;

    const findStayLink: React.ComponentProps<typeof DxUiHeader>['findStayLink'] = {
      url: `${env.OHW_BASE_URL}${languageCode}/book/reservation/find/`,
    };

    const onSignInAttempt = React.useCallback(
      (data: LoginResponse): Promise<void> => {
        const isError = !!data.error;

        if (!isError) {
          sendReward(Goals.SignInSuccess);
        }
        if (data) {
          setTrackSignIn(true);
          return login(data);
        }
        return Promise.reject();
      },
      [login, setTrackSignIn]
    );

    const onSignOut = React.useCallback((): Promise<void> => logout(), [logout]);

    useEffect(() => {
      if (trackSignIn && !isLoading) {
        // We only want this to trigger once when a user has successfully signed in
        setTrackSignIn(false);
        const availableGoUserTypes: string[] | null = guestInfo?.hhonors?.packages?.filter
          ? guestInfo?.hhonors?.packages
              ?.filter((pack) => pack?.packageName && goUserTypes.includes(pack.packageName))
              ?.map((pack) => pack && pack.packageName)
          : null;

        metrics.trackUserLoggedIn?.([
          {
            hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber ?? '',
            tierName: guestInfo?.hhonors?.summary?.tierName ?? '',
            points: guestInfo?.hhonors?.summary?.totalPointsFmt ?? '',
            goUserTypes: availableGoUserTypes,
          },
        ]);
      }
    }, [guestInfo, isLoading, trackSignIn, metrics]);

    if (hideHeader) {
      return null;
    }
    const megaNavLinks = listData?.megaNavLinks;

    //https://jira.hilton.com/browse/NHCBP-5615
    const megaNavLinksWithConductrics =
      megaNavLinks && shouldDisplayUnderResorts
        ? applyConductricsNHCBP5615(megaNavLinks)
        : megaNavLinks?.map((link) => {
            if (hiltonGetaways.test(link.label)) {
              return {
                ...link,
                onClick: () => {
                  sendReward(Goals.HiltonGetaways);
                },
              };
            }
            return link;
          });

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        componentClassName="header"
      >
        <DxUiHeader
          theme={theme}
          brand={brand}
          megaNavLinks={megaNavLinksWithConductrics}
          mainNavLinks={listData?.mainNavLinks}
          onSignInAttempt={onSignInAttempt}
          onSignOut={onSignOut}
          user={user}
          userLinks={userLinks}
          loginOptions={loginOptions}
          findStayLink={findStayLink}
          {...(showLanguageSelector && {
            languageSelectorOptions: {
              appName: 'dx-cpm-live',
              locale: router.locale || 'en',
              ...(megaNavLinksWithConductrics && {
                labelOptions: {
                  className: 'text-sm',
                },
                buttonOptions: {
                  className: 'text-sm',
                },
              }),
            },
          })}
        />
      </BrandComponentThemeInline>
    );
  }
);

function mapMegaNavLink(link?: OscDomLink) {
  return {
    label: link?.label || '',
    link: {
      url: link?.url || '',
      isNewWindow: !!link?.isNewWindow,
      showNewWindowIcon: !!link?.isNewWindow,
    },
  };
}

function isNavigationLinks(items: unknown): items is CpmNavigationList['items'] {
  return Array.isArray(items) && items.length > 0 && 'links' in items[0];
}

function isBrandNavigationLinks(items: unknown): items is CpmBrandNavigationList['items'] {
  return Array.isArray(items) && items.length > 0 && 'primaryNav' in items[0];
}
