import { TextBlockBody, TextBlockHeader } from '@dx-ui/osc-marketing';
import cx from 'classnames';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { Link as Linktype } from '@dx-ui/osc-link';

export type AttachedContentProps = {
  headline?: string;
  description?: string | null;
  link?: Linktype | null;
  alignContent?: CmsAlignContent;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const DocsTAttachedContent: React.FC<React.PropsWithChildren<AttachedContentProps>> = () =>
  null;

export const AttachedContent = ({
  headline,
  description,
  link,
  alignContent = 'center',
  brandComponentTheme,
}: AttachedContentProps) => {
  const textAlignCss = {
    'text-left': alignContent === 'left',
    'text-right': alignContent === 'right',
    'text-center': alignContent === 'center',
  };

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      data-testid="carousel-attached-content"
      className={cx('relative py-10 text-center container', {
        'bg-bg-light': isLight,
        'bg-bg-dark': isDark,
      })}
    >
      {headline ? (
        <TextBlockHeader
          className={cx(textAlignCss, 'heading-4xl sm:heading-5xl lg:heading-6xl', {
            '!text-text-inverse': isDark,
            'video-poster-headline-light': isLight,
          })}
        >
          {headline}
        </TextBlockHeader>
      ) : null}

      {description ? (
        <TextBlockBody
          className={cx(textAlignCss, {
            'text-text-inverse': isDark,
            'video-poster-long-description-light': isLight,
          })}
          brandComponentTheme={brandComponentTheme}
        >
          {description}
        </TextBlockBody>
      ) : null}

      {link ? (
        <div className={cx('p-0 mt-2 xl:mt-4', textAlignCss)}>
          <BrandLink
            isNewWindow={link.isNewWindow}
            showNewWindowIcon={link.isNewWindow}
            url={link.url}
            label={link.label}
            brandComponentTheme={brandComponentTheme}
          />
        </div>
      ) : null}
    </div>
  );
};
