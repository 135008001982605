import Icon from '@dx-ui/osc-icon';
import classnames from 'classnames';
import type { VideoControlsProps } from './video-player.controls';

export function ControlIcon({
  brandComponentTheme,
  ...iconProps
}: React.ComponentProps<typeof Icon> & Pick<VideoControlsProps, 'brandComponentTheme'>) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return (
    <Icon
      {...iconProps}
      className={classnames(
        'fill-primary',
        {
          'fill-text-inverse brand-es-refresh:fill-primary brand-gu:fill-primary brand-nd:fill-primary':
            isDark,
          'brand-ht:fill-text-inverse': isLight,
        },
        iconProps.className
      )}
    />
  );
}
