import { sendReward } from '@dx-ui/framework-conductrics';
import type { TDrawerItem, TDrawerLink } from '@dx-ui/osc-header';
import type { TabProps } from '../mapping/TabbedSection/VerticalTabs.types';

const SIGN_IN = /sign in/i;
const HH_APP = /hilton honors app/i;
const JOIN_HH = /join hilton honors/i;

export const Goals = {
  SignInSuccess: 'g-IqzR2S0Ai5',
  JoinHiltonHonorsTAB: 'g-2Mx3NVgKEo',
  JoinForFreeCTA: 'g-YALuwfEhWZ',
  EngagementWithVideoHONORS: 'g-R6YcyFiTIc',
  HiltonHonorsAppTAB: 'g-ceePuT4jz0',
  EngagementWithVideoAPP: 'g-eji8qd4d2v',
  DownloadForFreeCTA: 'g-JvISPYafgq',
  SignInCTA: 'g-330wZxWuoO',
  HiltonGetaways: 'g-hilton-getaways',
  FindAHotelClick: 'g-DD3G7VqYaV',
  EditBrandSearchWidget: 'g-C6bnRUoR2d',
} as const;

export function applyNHCBP5717Conductrics<
  T extends { tab: TabProps['tab']; panel: TabProps['panel'] }
>(items: T[], isVariantB = false): T[] {
  return items.map((item) => {
    const label = item.tab?.label?.trim();

    if (SIGN_IN.test(label)) {
      return {
        ...item,
        panel: {
          ...item.panel,
          ...(isVariantB
            ? {
                image: undefined,
                media: {
                  video: {
                    source: 'youtube',
                    title: 'How To Earn Hilton Honors Points During Your Stay',
                    url: 'https://www.youtube.com/watch?v=J1AFPuhEAxQ',
                    id: '509ffe97-4b7c-48cb-94c1-bd29edde7b6c',
                  },
                },
              }
            : {}),
          link: {
            ...item.panel.link,
            onClick: () => {
              sendReward(Goals.SignInCTA);
              sendReward(Goals.SignInSuccess);
            },
          },
        },
        tab: { ...item.tab },
      };
    }
    if (HH_APP.test(label)) {
      return {
        ...item,
        panel: {
          ...item.panel,
          ...(isVariantB
            ? {
                image: undefined,
                media: {
                  video: {
                    source: 'youtube',
                    title: 'How To Check In and Choose Your Room with the Hilton Honors App',
                    url: 'https://www.youtube.com/watch?v=4-_XCYuDm-4',
                    onStateChange: (e: { data: number }) => {
                      const isPlaying = e?.data === 1 ? true : false;
                      const isPaused = e?.data === 2 ? true : false;
                      if (isPlaying || isPaused) sendReward(Goals.EngagementWithVideoAPP);
                    },
                    id: '7c759a44-bf67-4b44-89c5-20d2b473be54',
                  },
                },
              }
            : {}),
          link: { ...item.panel.link, onClick: () => sendReward(Goals.DownloadForFreeCTA) },
        },
        tab: { ...item.tab, onTabChange: () => sendReward(Goals.HiltonHonorsAppTAB) },
      };
    }
    if (JOIN_HH.test(label)) {
      return {
        ...item,
        panel: {
          ...item.panel,
          ...(isVariantB
            ? {
                image: undefined,
                media: {
                  video: {
                    source: 'youtube',
                    title: 'How To Earn Instant Value from Hilton Honors',
                    url: 'https://www.youtube.com/watch?v=JvbtSC3BXL4',
                    onStateChange: (e: { data: number }) => {
                      const isPlaying = e?.data === 1 ? true : false;
                      const isPaused = e?.data === 2 ? true : false;
                      if (isPlaying || isPaused) sendReward(Goals.EngagementWithVideoHONORS);
                    },
                    id: 'e68a56d3-a798-4832-97cd-388c9b267e45',
                  },
                },
              }
            : {}),
          link: { ...item.panel.link, onClick: () => sendReward(Goals.JoinForFreeCTA) },
        },
        tab: { ...item.tab, onTabChange: () => sendReward(Goals.JoinHiltonHonorsTAB) },
      };
    }

    return item;
  });
}

/**
 * Start of https://jira.hilton.com/browse/NHCBP-5615 code (to be removed after AB test results)
 */

const hotelsResorts = /Hotels & Resorts/i;
export const hiltonGetaways = /Hilton Getaways/i;

function checkLabelsExist(megaNavLinks: Array<TDrawerItem>) {
  let label1Exists = false;
  let label2Exists = false;

  for (const obj of megaNavLinks) {
    if (hotelsResorts.test(obj.label)) {
      label1Exists = true;
    }
    if (hiltonGetaways.test(obj.label)) {
      label2Exists = true;
    }
  }

  return label1Exists && label2Exists;
}

export const applyConductricsNHCBP5615 = (megaNavLinks: Array<TDrawerItem>) => {
  //making sure both Hilton Getaways and Hotels & Resorts are present in the nav links
  if (!checkLabelsExist(megaNavLinks)) return megaNavLinks;

  let hiltonGetaway: TDrawerItem;

  //removing Hilton Getaways
  const navLinksWithoutGetaway = megaNavLinks.filter((link) => {
    if (!hiltonGetaways.test(link.label)) {
      return true;
    }
    hiltonGetaway = link;
    return false;
  });

  //Putting Hilton Getaway under Hotels and Resorts tab (sub menu)
  return navLinksWithoutGetaway.map((link) => {
    if (hotelsResorts.test(link.label)) {
      const newLink = { ...link };

      if (newLink.subMenu) {
        newLink.subMenu = [
          ...newLink.subMenu,
          {
            label: hiltonGetaway.label,
            link: hiltonGetaway.link,
            onClick: () => {
              sendReward(Goals.HiltonGetaways);
            },
          } as TDrawerLink,
        ];
      } else {
        newLink.subMenu = [
          {
            label: hiltonGetaway.label,
            link: hiltonGetaway.link,
            onClick: () => {
              sendReward(Goals.HiltonGetaways);
            },
          } as TDrawerLink,
        ];
      }

      return newLink;
    }

    return { ...link };
  });
};

/**
 * End of https://jira.hilton.com/browse/NHCBP-5615 code
 */
