import { isPortfolioRedirect } from './is-portfolio-redirect';

type GetBrandRouteParamsResult = {
  brandSlug: string;
  brandPath: string;
  auxPageSlug: string;
  isStaticPage: boolean;
  isStaticCMSPage: boolean;
  lang: string;
  isValid: boolean;
};

const excludedSegments = ['', 'brands', 'home', 'index'];

export function getBrandRouteParams(path: string, language: string): GetBrandRouteParamsResult {
  const segments = path
    .split('?')[0]
    .split('/')
    .filter((segment) => segment !== language && !excludedSegments.includes(segment));

  const [brandSlug, ...otherSegments] = segments;

  // Include the channel slug in the brand path if we've been redirect
  // and handle portfolio redirects - they need to retain the auxiliary
  // page in the brand path
  let brandPath = isPortfolioRedirect(segments) ? segments.join('/') : otherSegments.join('/');

  // Set brand path to '/' when there are no other segments
  if (brandPath === '') {
    brandPath = '/';
  }

  return {
    brandSlug,
    brandPath,
    auxPageSlug: otherSegments.join('/'),
    lang: language,
    // The app doesn't render static pages
    isStaticPage: false,
    // The app doesn't render static content pages from the CMS
    isStaticCMSPage: false,
    // The URL will always be valid at this point
    isValid: true,
  };
}
