import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';

export default createCpmComponentDefinition(
  'Accordion',

  function mapComponentData({ data }) {
    return data;
  },

  function CpmAccordion({ items = [] }) {
    if (items) {
      return (
        <div className="p-1">
          <h2 className="text-lg font-bold">Accordion Placeholder Component</h2>
          <h3>Debug data</h3>
          <div className="max-h-64 overflow-y-scroll">
            <pre>
              <code>{JSON.stringify(items, null, 2)}</code>
            </pre>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
);
