import * as GeneratedQueries from './generated/queries';

export * from './types';
export type { BrandsQuery as BrandsQueryType } from './generated/types';
export const { useBrandsQuery, serverSideBrandsQuery } = GeneratedQueries;

export * from './components/CpmPage';
export * from './components/CustomHead';
export * from './components/Error';
export { default as Layout } from './components/Layout';
export type { FormData } from './components/Search';
export { Search } from './components/Search';

export { useSingleInstanceQueryStrings } from './hooks/use-query-strings';

export { getBaseUrl } from './utils/constants';
export { getBrandTaggingValues } from './utils/get-brand-tagging-values';
export { getQueryProviderProps } from './utils/get-query-provider-props';
export type { SearchUrlParameters } from './utils/search';
export { generateUrl } from './utils/search';
export { mapDataToSpecialRates } from './utils/map-data-to-special-rates';

export { useMetrics } from './metrics/metrics';

export { namespaces, mappingSpec, Header } from './generatedCpmInterface';

export { goUserTypes } from './utils/constants';
export type { CpmData, CpmServerBundleWithLanguages } from './utils/fetch-server-cpm-page';
export {
  fetchServerCpmPageWithSupportedLanguages,
  FetchServerCpmPage404Error,
} from './utils/fetch-server-cpm-page';
