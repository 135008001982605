import * as React from 'react';
import type {
  EnvironmentVariables,
  FeatureToggles,
  GetCompanyByAccountIdQuery,
  TBrandShowcase,
} from '@dx-ui/cpm-mapping-shared';
import {
  PreviewSegmentsContext,
  getBrandRouteParams,
  useInitializeConductrics,
  BrandShowcaseContext,
  EnvironmentVariablesContext,
  FeatureTogglesContext,
  CorporateAccountContext,
  BrandDataContext,
  EMBASSY_REFRESH_TOGGLE_NAME,
} from '@dx-ui/cpm-mapping-shared';

import Layout from './Layout';
import type { TBrandsQuery } from '../types/gql';
import { useRouter } from 'next/router';
import { getBaseUrl } from '../utils/constants';
import { BrandThemeWrapper } from '@dx-ui/osc-marketing';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { GenericError } from '@dx-ui/cpm-sdk';
import { useMetrics } from '../metrics/metrics';
import { CpmRenderer } from '../generatedCpmInterface';
import { getBrandTaggingValues } from '../utils/get-brand-tagging-values';
import type { CpmData } from '../utils/fetch-server-cpm-page';
import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';

interface CpmPageProps {
  brandCode: string;
  brandData?: TBrandsQuery;
  cpmData: CpmData;
  corporateAccount: GetCompanyByAccountIdQuery | null;
  localeCode: string;
  brandShowcaseData: TBrandShowcase;
  isCPMEditor?: boolean;
  featureToggles?: FeatureToggles;
  env: EnvironmentVariables;
  includeBackToTopButton?: boolean;
  previewSegments?: string[];
}

function ConductricsInitalizer() {
  useInitializeConductrics();

  return null;
}

/**
 * Wrapper providing a neater interface around Providers and BloomReachPage.
 */
export function CpmPage({
  brandCode,
  brandData,
  cpmData,
  corporateAccount,
  brandShowcaseData,
  localeCode,
  isCPMEditor = false,
  featureToggles = [],
  env,
  includeBackToTopButton = true,
  previewSegments,
}: CpmPageProps): JSX.Element {
  const router = useRouter();
  const { authClient } = useAuth();

  const { language: languageCode } = new Intl.Locale(localeCode);
  const baseAppUrl = getBaseUrl(env, `${languageCode}${router.asPath}`);
  const { brandPath: fullBrandPath } = getBrandRouteParams(router.asPath, languageCode);
  const metricsInstance = useMetrics(
    localeCode,
    fullBrandPath,
    cpmData.mappedPage,
    brandData,
    isCPMEditor,
    env
  );

  if (!brandCode) {
    return GenericError({
      description1: 'Page is missing `brandCode`',
      description2: '',
    });
  }

  if (!brandData) {
    return GenericError({
      description1: 'Missing brand data',
      description2: `Brand code: "${brandCode}" can't be found in "query brands"`,
    });
  }

  if (!authClient) {
    throw new Error('Unable to load authClient');
  }

  const embassyRefreshToggleEnabled = getIsFeatureToggleEnabled({
    featureToggleData: { featureToggles },
    name: EMBASSY_REFRESH_TOGGLE_NAME,
  });

  return (
    <BrandShowcaseContext.Provider value={brandShowcaseData}>
      <EnvironmentVariablesContext.Provider value={env}>
        <FeatureTogglesContext.Provider value={featureToggles}>
          <CorporateAccountContext.Provider value={corporateAccount}>
            <BrandDataContext.Provider value={brandData}>
              <PreviewSegmentsContext.Provider value={previewSegments ?? []}>
                <BrandThemeWrapper
                  brandCodeForTheme={brandCode === 'WW' ? 'HH' : brandCode}
                  embassyRefreshToggleEnabled={embassyRefreshToggleEnabled}
                >
                  <CpmRenderer
                    cpmData={cpmData}
                    sectionNames={['top', 'main', 'footer']}
                    metrics={metricsInstance}
                    mappingName="Brands"
                    isCPMEditor={isCPMEditor}
                    campaignCodeTaggingValues={getBrandTaggingValues}
                  >
                    {({ top, footer, main }: Record<string, React.ReactNode>) => (
                      <React.Fragment>
                        <ConductricsInitalizer />
                        <Layout
                          env={env}
                          top={top}
                          bottom={footer}
                          brandCode={brandCode}
                          localeCode={localeCode}
                          baseAppUrl={baseAppUrl}
                          fullBrandPath={fullBrandPath}
                          brandData={brandData}
                          mappedPage={cpmData.mappedPage}
                          includeBackToTopButton={includeBackToTopButton}
                          supportedLanguages={
                            'supportedLanguages' in cpmData ? cpmData.supportedLanguages : []
                          }
                        >
                          {main}
                        </Layout>
                      </React.Fragment>
                    )}
                  </CpmRenderer>
                </BrandThemeWrapper>
              </PreviewSegmentsContext.Provider>
            </BrandDataContext.Provider>
          </CorporateAccountContext.Provider>
        </FeatureTogglesContext.Provider>
      </EnvironmentVariablesContext.Provider>
    </BrandShowcaseContext.Provider>
  );
}
