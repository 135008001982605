import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import {
  startOfWeek,
  differenceInCalendarDays,
  addDays,
  getDaysInMonth,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  subDays,
} from 'date-fns';

export const MAX_CALENDAR_DAYS = 42; // maximum day slots a calendar can have

export const DEFAULT_LANGUAGE = 'en' as SupportedLanguage;

export const DEFAULT_LOCALE_OPTIONS = {
  calendar: 'gregory',
};

export const LOCALE_OPTIONS: Partial<
  Record<SupportedLanguage | (string & NonNullable<unknown>), Partial<Intl.DateTimeFormatOptions>>
> = {
  ar: {
    numberingSystem: 'latn',
  },
};

export const weekStartsOn = (locale: Intl.Locale) => {
  const key = `${locale.language}${locale.script ? `-${locale.script.toLocaleLowerCase()}` : ''}`;
  return key ? weekStartsOnLocale[key] : 0;
};

export function getDaysSplitByWeeks({
  date,
  locale,
}: {
  date: Date;
  locale: Intl.Locale;
}): Date[][] {
  const startDay = differenceInCalendarDays(
    date,
    startOfWeek(date, { weekStartsOn: weekStartsOn(locale) })
  );
  const days = eachDayOfInterval({
    start: subDays(date, startDay),
    end: addDays(endOfMonth(date), MAX_CALENDAR_DAYS - (getDay(date) + getDaysInMonth(date))),
  });
  const w: Date[][] = [];
  while (days.length > 0) {
    w.push(days.splice(0, 7));
  }
  return w;
}

export const isKanji = (locale?: Intl.Locale) =>
  locale?.language && ['ja', 'ko', 'zh'].includes(locale?.language);
export const isLocale = (language: string, locale?: Intl.Locale) =>
  locale?.language && language === locale.language;
export const isArabic = (locale?: Intl.Locale) =>
  locale?.language && ['ar'].includes(locale?.language);

const weekStartsOnLocale: Record<string, 0 | 1 | 2 | 3 | 4 | 5 | 6> = {
  en: 0,
  ar: 6,
  bg: 1,
  cs: 1,
  da: 1,
  fi: 1,
  fr: 1,
  id: 1,
  nl: 1,
  de: 1,
  it: 1,
  ja: 0,
  ko: 0,
  no: 1,
  pl: 1,
  pt: 1,
  ro: 1,
  ru: 1,
  es: 1,
  sv: 1,
  th: 0,
  tr: 1,
  'zh-hans': 0,
  'zh-hant': 0,
};
