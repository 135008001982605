import cx from 'classnames';

interface Effects {
  animation?: boolean;
  backgroundImage?: boolean;
  blur?: boolean;
  leftWaffle?: boolean;
  rightWaffle?: boolean;
  svg?: boolean;
}

export const getEffectClassNames = (effects: Effects) =>
  cx({
    // Only background image effect
    'bg-img-overlay': effects.backgroundImage,
    // Display for medium to x-large screen only
    'bg-img-overlay hidden md:block 2xl:hidden': effects.leftWaffle,
    // Flipped view - display for medium to x-large screens only
    'bg-img-overlay horizontal-flip hidden md:block 2xl:hidden': effects.rightWaffle,
  });
