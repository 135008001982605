import { VideoPlayer } from '@dx-ui/osc-video-player';
import { AttachedContent, type AttachedContentProps } from './AttachedContent';

export interface VideoProps extends React.ComponentProps<typeof VideoPlayer> {
  attachedContent?: AttachedContentProps;
  brandComponentTheme?: CmsBrandComponentTheme;
}

/**
 * Full screen width video component, auto-play, with video controls and optional attached content with text and CTA link/button.
 */
export const VideoPoster = ({
  videoUrl,
  videoGroup,
  videoLabel,
  videoName,
  posterImageUrl,
  orientation = 'landscape',
  attachedContent,
  brandComponentTheme,
  isAutoPlay = true,
  captionData,
  audioTracks,
  captionTracks,
  transcriptTracks,
  markupSchemas,
}: VideoProps) => {
  return (
    <>
      <div data-testid="video-poster-wrapper">
        <VideoPlayer
          markupSchemas={markupSchemas}
          videoUrl={videoUrl}
          videoGroup={videoGroup}
          videoLabel={videoLabel}
          videoName={videoName}
          posterImageUrl={posterImageUrl}
          orientation={orientation}
          videoId="video-poster"
          captionData={captionData}
          audioTracks={audioTracks}
          captionTracks={captionTracks}
          transcriptTracks={transcriptTracks}
          isAutoPlay={isAutoPlay}
          brandComponentTheme={brandComponentTheme}
        />
      </div>

      {attachedContent && (
        <AttachedContent
          headline={attachedContent.headline}
          description={attachedContent.description}
          link={attachedContent.link}
          brandComponentTheme={brandComponentTheme}
          alignContent={attachedContent.alignContent}
        />
      )}
    </>
  );
};

export default VideoPoster;
