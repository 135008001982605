import type * as React from 'react';

import type { BrProps } from '@bloomreach/react-sdk';
import type { ContainerItem } from '@bloomreach/spa-sdk';
import { ErrorBlock } from './ErrorBlock';

export type FallbackProps = BrProps<ContainerItem> & {
  mappingName?: string;
  isCPMEditor?: boolean;
};

const Fallback: React.FC<BrProps<ContainerItem>> = (props: FallbackProps) => {
  const isLiveMode = !props.isCPMEditor;

  let message = 'This component type is not supported by CPM';

  if (props.mappingName) {
    message = `${message} in the ${props.mappingName} mapping library`;
  }

  return isLiveMode ? null : (
    <ErrorBlock
      title={<>Could not render {props.component?.getLabel() ?? 'Unknown Component'}</>}
      message={message}
    />
  );
};

export default Fallback;
