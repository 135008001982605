import {
  VerticalTabButton,
  VerticalTab,
  VerticalTabPanel,
  VerticalTabs,
} from '@dx-ui/osc-vertical-tabs';
import cx from 'classnames';
import Image from 'next/image';
import Icon from '@dx-ui/osc-icon';
import { TextBlockBody, TextBlockHeader } from '@dx-ui/osc-marketing';
import { iconMapper } from '../../utils/icon-mapper';
import type { TVerticalTabs } from './VerticalTabs.types';
import VerticalTabBody from './VerticalTabbedSectionContent';
import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';

export const VerticalTabbedSection = ({
  listHeadline,
  listDescription,
  textAlign = 'left',
  items,
  brandComponentTheme,
  onTabChange,
  id: cmsId,
}: TVerticalTabs) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const id = `tabs${cmsId}`;

  return (
    <section
      className={cx('container pb-6 pt-10 lg:py-16', {
        'bg-bg-light': isLight,
        'bg-bg-dark': isDark,
      })}
    >
      {listHeadline ? (
        <TextBlockHeader
          id={id}
          className={cx(
            mapTextAlignToClassname(textAlign),
            {
              'lg:pb-4': !!listDescription,
              'lg:pb-14': !listDescription,
              '!text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
            },
            'mx-auto my-0 whitespace-normal break-words pb-2 pt-4 text-center heading-xl tracking-wide md:heading-4xl md:tracking-tight lg:tracking-normal'
          )}
        >
          {listHeadline}
        </TextBlockHeader>
      ) : null}

      {listDescription ? (
        <TextBlockBody
          className={cx('pb-14 text-center text-lg', mapTextAlignToClassname(textAlign), {
            'text-text-inverse': isDark,
            'brand-ht:text-text-inverse': isLight,
          })}
          brandComponentTheme={brandComponentTheme}
        >
          {listDescription}
        </TextBlockBody>
      ) : null}

      <VerticalTabs
        defaultActiveId={items[0].id}
        id={id}
        accordionExpandedClassName={cx('!font-normal border-b !border-b-primary brand-gu:bg-bg', {
          'brand-gu:bg-primary-alt brand-gu:text-text-inverse': isLight || isDark,
          'brand-nd:bg-quarternary': isDark,
          'brand-nd:bg-bg-alt': !isDark,
        })}
        accordionCollapsedClassName="font-normal !border-b-0 last-of-type:!border-b"
        accordionClassName={cx(
          {
            'text-text-inverse !border-b-text-inverse': isDark,
            'brand-ht:text-text-inverse': isLight,
            'brand-ey:border-t-primary-alt brand-ey:last-of-type:border-b-primary-alt brand-gu:border-t-primary brand-gu:last-of-type:border-b-primary brand-ou:border-t-primary brand-ou:last-of-type:border-b-primary':
              !isDark,
            'brand-nd:border-t-primary brand-nd:last-of-type:border-b-primary': !isDark,
          },
          'border-t'
        )}
        accordionIconIndicatorFillColor={cx({
          'fill-bg-dark': isLight,
          'fill-bg-light brand-ht:fill-primary': isDark,
          'brand-lx:fill-brand': !isDark,
          'brand-ou:fill-secondary': !isDark && !isLight,
        })}
        onTabChange={onTabChange}
      >
        {items.map((item) => {
          return (
            <VerticalTab key={item.tab.label} className="pb-10">
              <VerticalTabButton
                tabId={item.id}
                inactiveClassName={cx(
                  'border-b-2 first:border-t-2 border-border-alt brand-ey:text-primary brand-nd:font-mono brand-nd:font-medium',
                  'brand-es-refresh:bg-transparent',
                  {
                    'bg-border-alt brand-ey:border-bg brand-ey:bg-bg brand-gu:bg-bg-light brand-ou:bg-secondary brand-ou:border-secondary brand-lx:bg-bg brand-lx:border-bg':
                      isLight,
                    'brand-es-refresh:text-text-inverse brand-es-refresh:border-bg': isDark,
                    'brand-ey:bg-transparent brand-ey:text-text-inverse brand-ey:hover:text-tertiary brand-ou:bg-secondary brand-ou:border-secondary':
                      isDark,
                    'brand-gu:bg-bg-dark brand-gu:text-text-inverse brand-gu:border-quarternary brand-gu:hover:text-secondary':
                      isDark,
                    'brand-nd:bg-bg-dark brand-nd:text-text-inverse brand-nd:border-bg brand-nd:hover:text-text-disabled':
                      isDark,
                    'brand-ey:bg-bg-light brand-ey:border-bg-light brand-ou:bg-bg-light brand-ou:border-bg-light brand-lx:bg-bg-light brand-lx:border-bg-light':
                      !isDark && !isLight,
                    'brand-gu:bg-quarternary': !isDark && !isLight,
                    'brand-es-refresh:border-text': !isDark,
                    'brand-ey:hover:text-secondary': !isDark,
                    'brand-gu:border-primary brand-gu:hover:text-primary-alt': !isDark,
                    'brand-nd:bg-transparent brand-nd:border-primary brand-nd:hover:text-primary-alt':
                      !isDark,
                  }
                )}
                activeClassName={cx(
                  {
                    'text-text-inverse before:!bg-bg-light brand-gu:bg-primary-alt brand-ou:before:bg-secondary brand-ou:border-secondary':
                      isDark,
                    'brand-ey:bg-quarternary-alt brand-ey:border-bg-light brand-ey:before:!bg-brand-alt':
                      isDark,
                    'brand-nd:bg-quarternary brand-nd:border-bg': isDark,
                    'brand-gu:bg-primary-alt brand-gu:text-text-inverse brand-gu:before:bg-primary':
                      isLight,
                    'brand-ht:text-text-inverse': isLight,
                    'brand-ey:border-primary-alt brand-ey:text-primary brand-gu:border-primary brand-lx:border-primary brand-ou:border-primary':
                      !isDark,
                    'brand-nd:border-primary brand-nd:bg-bg-alt': !isDark,
                    'brand-gu:bg-bg': !isDark && !isLight,
                    'brand-es-refresh:before:bg-secondary brand-es-refresh:border-text': !isDark,
                    'brand-es-refresh:bg-bg-alt': !isDark && !isLight,
                    'brand-es-refresh:bg-quarternary-alt': isLight,
                    'brand-es-refresh:bg-primary-alt brand-es-refresh:before:!bg-quarternary brand-es-refresh:border-bg':
                      isDark,
                  },
                  "z-10 font-bold border-b-2 first:border-t-2 border-border-alt before:content-[''] before:absolute before:start-0 before:bg-primary before:h-full before:w-2 brand-ey:font-normal brand-lx:before:bg-brand brand-nd:font-medium brand-nd:font-mono"
                )}
                className="w-80"
              >
                <div className="flex items-center lg:flex-row">
                  {item.iconType ? (
                    <Icon name={iconMapper[item.iconType]} size="lg" className="!rotate-0" />
                  ) : item.logoUrl ? (
                    <Image
                      width={50}
                      height={50}
                      src={item.logoUrl}
                      alt=""
                      unoptimized={true}
                      aria-hidden
                    />
                  ) : null}

                  {item.tab.label ? (
                    <span className="ms-3.5 pe-6 text-lg">{item.tab.label}</span>
                  ) : null}
                </div>
              </VerticalTabButton>

              <VerticalTabPanel
                tabPanelId={item.id}
                className={cx(
                  'border-border-alt -translate-x-1 border-t-2 ps-10 pt-2 rtl:translate-x-1',
                  {
                    'brand-es-refresh:border-bg brand-ou:!border-secondary brand-ey:border-bg-light brand-gu:border-quarternary brand-nd:border-bg':
                      isDark,
                    'brand-es-refresh:border-text brand-ey:border-primary-alt brand-gu:border-primary brand-lx:border-primary brand-nd:border-primary brand-ou:border-primary':
                      !isDark,
                  }
                )}
              >
                <VerticalTabBody
                  imageUrl={item.panel.image?.url}
                  captionData={item.panel.image?.captionData}
                  altText={item.panel.image?.alt}
                  headline={item.panel.headline}
                  text={item.panel.copy}
                  brandComponentTheme={brandComponentTheme}
                  link={item.panel.link}
                />
              </VerticalTabPanel>
            </VerticalTab>
          );
        })}
      </VerticalTabs>
    </section>
  );
};
