import classnames from 'classnames';
import type { VideoControlsProps } from './video-player.controls';

export function ControlItem({
  brandComponentTheme,
  beforeSection,
  afterSection,
  children,
  wrapperClassName,
  ...buttonProps
}: {
  beforeSection?: React.ReactNode;
  afterSection?: React.ReactNode;
} & Omit<React.ComponentProps<'button'>, 'type'> &
  Pick<VideoControlsProps, 'brandComponentTheme'> & {
    wrapperClassName?: React.ComponentProps<'li'>['className'];
  }) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return (
    <li className={classnames('p-1.5', wrapperClassName)}>
      <button
        {...buttonProps}
        type="button"
        className={classnames(
          'focus:z-2 relative flex w-full items-center gap-1 p-1 text-start',
          buttonProps.className
        )}
      >
        {beforeSection}
        <span
          className={classnames('text-primary underline', {
            'text-text-inverse brand-gu:text-primary brand-nd:text-primary': isDark,
            'brand-ht:text-text-inverse': isLight,
          })}
        >
          {children}
        </span>
        {afterSection}
      </button>
    </li>
  );
}
