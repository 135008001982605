import type { CpmMappedPage } from '@dx-ui/cpm-sdk';
import type { Multimedia } from './video';
import type { VideoPlayerMarkupProps } from '@dx-ui/osc-video-player';

type NoUndefinedField<T> = { [P in keyof T]: Exclude<T[P], null | undefined> };

type VideoMarkupMultimedia = Omit<
  Multimedia,
  'alternativeVideoLabel' | 'extended' | 'multimediaMetadata' | 'orientation' | 'videoAutoPlay'
>;

export type ValidatedVideoMarkupMultimedia = NoUndefinedField<Required<VideoMarkupMultimedia>>;

/**
 * Maps the CMS multimedia object to the props of our VideoPlayer's `markup` prop value.
 */
export function mapVideoMarkup(
  multimedia: ValidatedVideoMarkupMultimedia,
  mappedPage: Pick<CpmMappedPage, 'brandCode' | 'pathname'>
): NonNullable<VideoPlayerMarkupProps['markupSchemas']>[number] {
  const { brandCode = 'WW', pathname = '' } = mappedPage;
  return {
    name: multimedia.videoName,
    logo: `https://www.hilton.com/modules/assets/svgs/logos/${brandCode}.svg`,
    description: multimedia.description,
    thumbnailUrl: multimedia.posterImageUrl,
    uploadDate: multimedia.uploadDatetime.toString(),
    duration: multimedia.videoDuration,
    embedUrl: `https://www.hilton.com${pathname}`,
  };
}
