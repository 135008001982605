import type { NextPageContext } from 'next';
import { isBrowser } from './constants';

export const parseCookieValue = (cookiesString?: string): Record<string, string> | undefined =>
  cookiesString
    ?.split(';')
    ?.map((v) => v.split('='))
    ?.reduce(
      (acc, [key, val]) => ({
        ...acc,
        [decodeURIComponent(key.trim())]: decodeURIComponent(val?.trim()),
      }),
      {}
    );

export const parseCookieValueByName = (
  cookiesString: string,
  cookieName: string
): string | null => {
  const cookies = parseCookieValue(cookiesString);

  if (!cookies) {
    return null;
  }

  return cookies[cookieName] ?? null;
};

export const getCookieFromContext = (cookieName: string, context?: NextPageContext) => {
  if (isBrowser) {
    return parseCookieValueByName(document.cookie, cookieName);
  }
  if (context) {
    return parseCookieValueByName(context.req?.headers?.cookie || '', cookieName);
  }
  return null;
};
