import { useState } from 'react';
import { TabListButton, TabPanel, TabPanels, Tabs, TabListScrollable } from '@dx-ui/osc-tabs';
import cx from 'classnames';
import { TextBlockBody, TextBlockHeader } from '@dx-ui/osc-marketing';
import Icon from '@dx-ui/osc-icon';
import Image from 'next/image';

import { mapTextAlignToClassname } from '@dx-ui/osc-textual-block';
import type { HorizontalTabProps, HorizontalTabsProps } from './HorizontalTabs.types';
import { brandIconMapper, iconMapper } from '../../utils/icon-mapper';
import { HorizontalTabbedSectionContent } from './HorizontalTabbedSectionContent';

type TabIconProps = {
  iconType: string;
  isDark: boolean;
};

const TabIcon = ({ iconType = '', isDark = false }: TabIconProps) => {
  const brandIcon = iconType ? brandIconMapper[iconType] : null;
  if (brandIcon) {
    const { brandCode, name } = brandIcon;
    if (brandCode === 'ES') {
      const iconName = isDark ? brandIcon.invertedName : name;
      return <Icon brandCode={brandCode} name={iconName} size="5xl" />;
    }
    if (brandCode === 'EY') {
      return <Icon brandCode={brandCode} name={name} size="lg" />;
    }
    return null;
  }
  return <Icon name={iconMapper[iconType]} size="lg" />;
};

export const HorizontalTabbedSection = ({
  brandComponentTheme,
  defaultTab,
  listDescription,
  hasWaffle,
  listHeadline,
  textAlign = 'left',
  isThemeAdded = false,
  isFull = false,
  items,
  id,
  onTabChange,
  localeCode,
  wrapperRef,
}: HorizontalTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  if (!items.length) return null;

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <section
      className={cx('relative container', {
        'w-full': isFull,
        'pb-14 lg:pt-16 lg:pb-20': isThemeAdded,
        'pb-6 lg:py-16': !isThemeAdded,
        'bg-bg-light brand-wa:bg-transparent': isLight,
        'bg-bg-dark': isDark,
        'bg-transparent': !isDark && !isLight,
      })}
      data-testid="tabbedsection-items-wrapper"
      ref={wrapperRef}
    >
      {items.length > 1 ? (
        <>
          {listHeadline ? (
            <TextBlockHeader
              id={id}
              className={cx(
                'lg:mb-2 brand-gu:text-center brand-up:text-center',
                mapTextAlignToClassname(textAlign),
                {
                  '!text-text-inverse': isDark,
                  'brand-ht:!text-text-inverse': isLight,
                }
              )}
            >
              {listHeadline}
            </TextBlockHeader>
          ) : null}

          {listDescription ? (
            <TextBlockBody
              brandComponentTheme={brandComponentTheme}
              className={cx(
                'text-text py-2 pr-8 sm:pr-6 sm:text-lg lg:text-xl',
                'brand-gu:text-center brand-up:mb-12 brand-up:text-center',
                mapTextAlignToClassname(textAlign),
                {
                  'text-text-inverse': isDark,
                  'brand-ht:text-text-inverse': isLight,
                }
              )}
            >
              {listDescription}
            </TextBlockBody>
          ) : null}

          <Tabs
            key={`${id}-${items.length}`}
            changeWithArrow
            className={cx({
              'md:pt-28 md:pb-36 2xl:pt-4 2xl:pb-0': hasWaffle,
            })}
            onTabChange={(id) => {
              setSelectedTab(id);
              onTabChange?.(id ?? '');
            }}
            defaultActive={defaultTab}
            lang={localeCode}
          >
            <TabListScrollable
              id={id}
              lang={localeCode}
              data-testid={`tablist-${id}`}
              className={cx(
                'mb-3 flex flex-row flex-nowrap overflow-x-scroll scrollbar-hide border-b',
                'brand-ou:border-b-primary',
                {
                  'border-b-bg brand-ou:border-b-secondary brand-ey:border-b-bg-light brand-gu:border-b-secondary':
                    isDark,
                  'brand-ht:border-b-bg': isLight,
                  'brand-gu:border-b-primary brand-nd:border-b-primary': !isDark,
                }
              )}
              leftArrowWrapperClassName={{
                'from-bg-dark text-text-inverse': isDark,
                'from-bg-light brand-ht:text-text-inverse': isLight,
                'brand-ou:from-secondary brand-gu:from-quarternary': !isDark && !isLight,
              }}
              rightArrowWrapperClassName={{
                'from-bg-dark text-text-inverse': isDark,
                'from-bg-light brand-ht:text-text-inverse': isLight,
                'brand-ou:from-secondary brand-gu:from-quarternary': !isDark && !isLight,
              }}
            >
              {items.map(({ id, tab: { label }, cmsTranslationClasses, logoUrl, iconType }) => (
                <TabListButton
                  data-testid={label}
                  activeClassName={cx(
                    'border-b-primary z-10 is-selected group selected',
                    'brand-ey:border-b-secondary',
                    'brand-hp:border-b-brand',
                    'brand-lx:border-b-brand',
                    {
                      'brand-ht:border-b-bg brand-gu:border-b-primary': isLight,
                      '!border-b-bg brand-es-refresh:!border-b-quarternary brand-gu:!border-b-quarternary brand-ht:border-b-bg brand-ou:!border-b-secondary brand-ey:!border-b-brand-alt':
                        isDark,
                      'brand-gu:border-b-secondary': !isDark && !isLight,
                      'brand-gu:!border-b-primary': !isDark && !iconType && !logoUrl,
                      'brand-es-refresh:border-b-secondary': !isDark,
                    }
                  )}
                  className={cx(
                    'hover:text-primary focus:text-primary grow cursor-pointer appearance-none border-b-4 border-transparent px-4 py-2 font-bold md:whitespace-normal bg-transparent',
                    {
                      'min-w-[50%] lg:min-w-[20%]': items.length > 5,
                      'min-w-[50%] lg:min-w-fit': items.length <= 5,
                      'flex items-baseline': logoUrl,
                    }
                  )}
                  id={id}
                  key={id}
                >
                  <div
                    className={cx(
                      'text-text flex min-w-[120px] flex-col items-center m-auto group',
                      {
                        'hover:text-brand focus:text-brand brand-es-refresh:hover:text-primary brand-es-refresh:focus:text-primary brand-lx:hover:text-primary-alt brand-lx:focus:text-primary-alt':
                          id !== selectedTab,
                      },
                      cmsTranslationClasses
                    )}
                  >
                    {logoUrl ? (
                      <Image
                        src={logoUrl}
                        alt=""
                        width={70}
                        height={70}
                        unoptimized={true}
                        aria-hidden
                      />
                    ) : null}
                    {iconType ? (
                      <div
                        className={cx(
                          'group-[.selected]:bg-bg-alt flex h-16 w-16 items-center justify-center rounded-full',
                          'brand-ou:group-[.selected]:bg-bg-light',
                          'brand-es-refresh:group-[.selected]:bg-transparent',
                          {
                            'text-text-inverse group-[.selected]:text-text brand-ou:group-[.selected]:bg-secondary brand-ou:group-[.selected]:text-primary':
                              isDark,
                            'brand-ey:bg-bg-light brand-ey:text-primary brand-ey:group-[.selected]:text-primary brand-ey:group-[.selected]:bg-brand-alt':
                              isDark,
                            'brand-gu:bg-bg-light brand-gu:text-primary brand-gu:group-[.selected]:bg-quarternary':
                              isDark,
                            'brand-nd:bg-text-disabled brand-nd:text-primary brand-nd:group-[.selected]:bg-bg brand-nd:group-[.selected]:text-primary':
                              isDark,
                            'brand-gu:bg-bg-dark brand-gu:text-text-inverse brand-gu:group-[.selected]:bg-quarternary brand-gu:group-[.selected]:text-primary':
                              isLight,
                            'brand-ou:group-[.selected]:bg-secondary': isLight,
                            'brand-ey:bg-bg-dark brand-ey:group-[.selected]:bg-secondary brand-ey:text-text-inverse brand-ey:group-[.selected]:text-text-inverse':
                              !isDark,
                            'brand-nd:bg-text-disabled brand-nd:group-[.selected]:bg-bg-dark brand-nd:group-[.selected]:text-text-inverse':
                              !isDark,
                            'brand-gu:bg-bg-dark brand-gu:text-text-inverse brand-gu:group-[.selected]:bg-bg-light brand-gu:group-[.selected]:text-primary':
                              !isDark && !isLight,
                          }
                        )}
                      >
                        <TabIcon iconType={iconType} isDark={isDark} />
                      </div>
                    ) : null}
                    <p
                      className={cx(
                        'stroke-text-inverse py-4 text-lg',
                        'brand-es-refresh:font-headline',
                        'brand-ey:font-normal brand-ey:text-primary brand-ey:hover:text-primary-alt',
                        'brand-gu:text-primary',
                        'brand-nd:uppercase brand-nd:font-mono',
                        'brand-wa:uppercase brand-wa:font-sans brand-wa:font-light brand-wa:text-text',
                        {
                          'font-bold brand-nd:font-medium': id === selectedTab,
                          'font-medium brand-gu:font-bold': id !== selectedTab,
                          '!text-text-inverse': isDark,
                          'brand-nd:group-hover:!text-text-disabled': isDark && id !== selectedTab,
                          'brand-ht:!text-text-inverse': isLight,
                          'brand-nd:group-hover:text-primary-alt': !isDark && id !== selectedTab,
                        }
                      )}
                      data-cpm-product="tabbed-section-label"
                    >
                      {label}
                    </p>
                  </div>
                </TabListButton>
              ))}
            </TabListScrollable>
            <TabPanels>
              {items.map(
                ({
                  id,
                  cmsDocumentControl,
                  panel: { media, image, headline, longDescription, copy, link },
                  cmsTranslationClasses,
                }: HorizontalTabProps) => {
                  return (
                    <TabPanel id={id} key={id} className={cmsTranslationClasses}>
                      <HorizontalTabbedSectionContent
                        brandComponentTheme={brandComponentTheme}
                        copy={copy}
                        longDescription={longDescription}
                        headline={headline}
                        image={image}
                        link={link}
                        media={media}
                      />
                      {cmsDocumentControl}
                    </TabPanel>
                  );
                }
              )}
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <>
          <HorizontalTabbedSectionContent
            brandComponentTheme={brandComponentTheme}
            copy={items[0].panel.copy}
            longDescription={items[0].panel.longDescription}
            headline={items[0].panel.headline}
            image={items[0].panel.image}
            link={items[0].panel.link}
            media={items[0].panel.media}
          />
          {items[0].cmsDocumentControl}
        </>
      )}
    </section>
  );
};
