import { LANGUAGES } from './constants';

const brandsWithBrandPage = [
  'PY',
  'CH',
  'QQ',
  'DT',
  'ES',
  'HP',
  'GI',
  'HI',
  'HT',
  'HW',
  'OL',
  'SA',
  'UA',
  'UP',
  'RU',
  'PO',
  'WA',
  'PE',
];

export const constructHreflangUrl = (path: string, newLang: string, currentLang: string) => {
  const origin = new URL(path).origin;

  const pathName = new URL(path).pathname
    .split('/')
    .filter((pathParam) => {
      return pathParam !== '';
    })
    .map((pathParam, index) => {
      if (pathParam === currentLang && index === 0) {
        return newLang;
      }

      return pathParam;
    })
    .join('/');

  return origin + '/' + pathName + '/';
};

export const isBrandHomePageUrlFormat = (pathname: string, brandCode: string) => {
  const pathParts = pathname.split('/').filter((n) => !!n);

  if (pathParts.length > 3) {
    return false;
  }

  const languages: string[] = Object.values(LANGUAGES);

  if (!languages.includes(pathParts[0])) {
    return false;
  }

  if (pathParts[1] !== 'brands') {
    return false;
  }

  if (!brandsWithBrandPage.includes(brandCode)) {
    return false;
  }

  return true;
};
