import type { Multimedia as AwardMultimedia } from 'dx-shared-schema/dist/types/couchbase/award';
import type { Multimedia as BrandMultimedia } from 'dx-shared-schema/dist/types/couchbase/brand';
import type { Multimedia as EventMultimedia } from 'dx-shared-schema/dist/types/couchbase/event';
import type { Multimedia as LocationMultimedia } from 'dx-shared-schema/dist/types/couchbase/location';
import type { Multimedia as PartnerMultimedia } from 'dx-shared-schema/dist/types/couchbase/partner';
import type { Multimedia as PlaceMultimedia } from 'dx-shared-schema/dist/types/couchbase/place';
import type { Multimedia as StoryMultimedia } from 'dx-shared-schema/dist/types/couchbase/story';
import type { VideoPlayer, VideoPlayerMarkup, VideoPlayerProps } from '@dx-ui/osc-video-player';
import { mapVideoMarkup } from './map-video-markup';
import type { ValidatedVideoMarkupMultimedia } from './map-video-markup';
import type { CpmMappedPage } from '@dx-ui/cpm-sdk';

export type Multimedia =
  | AwardMultimedia
  | BrandMultimedia
  | EventMultimedia
  | LocationMultimedia
  | PartnerMultimedia
  | PlaceMultimedia
  | StoryMultimedia;

type MultimediaType = Multimedia['multimediaMetadata'][number]['type'];

export function filterByTrackType(
  multimediaMetadata: Multimedia['multimediaMetadata'],
  type: `${MultimediaType}`
) {
  return multimediaMetadata.filter((metadata) => metadata.type === type);
}

export function mapMultimediaListToVideoProps(
  multimediaList: Multimedia[],
  mappedPage: CpmMappedPage
): React.ComponentProps<typeof VideoPlayer> {
  const [firstVideo, ...additionalVideos] = multimediaList;
  const videoTrack = mapMultimediaItemToAudioTrack(firstVideo);

  /**
   * We want the NonNullable type of our props. This guarantees that the data we are
   * using within our render is there.
   */
  const markupSchemas: Required<React.ComponentProps<typeof VideoPlayerMarkup>['markupSchemas']> =
    multimediaList
      .filter((multimedia) => multimedia.description)
      .map((multimedia) =>
        mapVideoMarkup(multimedia as ValidatedVideoMarkupMultimedia, mappedPage)
      );

  return {
    ...videoTrack,
    audioTracks: additionalVideos.map(mapMultimediaItemToAudioTrack),
    isAutoPlay: firstVideo.videoAutoPlay,
    orientation: firstVideo.orientation,
    posterImageUrl: firstVideo.posterImageUrl,
    markupSchemas,
  };
}

function mapMultimediaItemToAudioTrack(
  multimedia: Multimedia
): Omit<VideoPlayerProps, 'audioTracks'> {
  return {
    videoName: multimedia.videoName,
    videoUrl: multimedia.videoUrl,
    videoGroup: getVideoGroup(multimedia),
    videoLabel: multimedia.alternativeVideoLabel || '',
    captionTracks: filterByTrackType(multimedia.multimediaMetadata, 'captionTrack'),
    transcriptTracks: filterByTrackType(multimedia.multimediaMetadata, 'transcriptTrack'),
  };
}

export function getVideoGroup(multimedia: Multimedia): NonNullable<VideoPlayerProps['videoGroup']> {
  return multimedia.extended ? 'extended' : 'standard';
}
