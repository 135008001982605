import { useRef } from 'react';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { TextBlockBody, TextBlockHeader } from '@dx-ui/osc-marketing';
import { useRect } from '@dx-ui/utilities-use-rect';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import cx from 'classnames';
import type { TVerticalTabBody } from './VerticalTabs.types';

export const VerticalTabBody = ({
  imageUrl,
  captionData,
  brandComponentTheme,
  altText,
  headline,
  text,
  link,
}: TVerticalTabBody) => {
  const { url, label, isNewWindow } = link;
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const isImage = imageUrl && altText ? true : false;
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div
      className={cx({
        'flex flex-col xl:flex-row xl:items-start': isImage,
        'px-0 lg:px-6': !isImage,
      })}
    >
      {imageUrl && altText ? (
        <div
          ref={ref}
          style={{ flex: 1.3 }}
          className="flex items-center justify-center overflow-hidden"
        >
          <ResponsiveImage
            imageUrl={imageUrl}
            altText={altText}
            aspectRatio="3:2"
            width={rect?.width ?? 0}
            captionData={captionData}
          />
        </div>
      ) : null}
      <div
        className={cx({
          'z-1 flex flex-1 flex-col items-center justify-center text-left': isImage,
        })}
      >
        <div
          className={cx({
            'pt-12 md:mb-2 md:py-4 md:ps-4 lg:ps-10': isImage,
          })}
        >
          <TextBlockHeader
            className={cx({
              '!text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
            })}
          >
            {headline}
          </TextBlockHeader>
          <TextBlockBody
            className={cx('mb-8 pe-8 sm:pe-6 lg:mb-12', {
              'text-text-inverse brand-ht:text-text-inverse': isDark,
              'brand-ht:text-text-inverse': isLight,
            })}
            brandComponentTheme={brandComponentTheme}
          >
            {text}
          </TextBlockBody>

          {label && url ? (
            <BrandLink
              url={url}
              label={label}
              isNewWindow={isNewWindow}
              showNewWindowIcon={isNewWindow}
              brandComponentTheme={brandComponentTheme}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VerticalTabBody;
