import { useMemo } from 'react';
import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import { getBaseUrl } from './constants';

export const getAssetPath = (env: EnvironmentVariables) => `${getBaseUrl(env)}/modules/assets`;

const getSvgPath = (env: EnvironmentVariables) => `${getAssetPath(env)}/svgs`;

export const getSvgAssetUrls = (env: EnvironmentVariables) => ({
  home: `${getSvgPath(env)}/guest/home.svg`,
  phone: `${getSvgPath(env)}/common/phone.svg`,
  search: `${getSvgPath(env)}/common/search.svg`,
  account: `${getSvgPath(env)}/guest/account.svg`,
});

export const getBrandSvg = (env: EnvironmentVariables, brandCode: string) =>
  `${getSvgPath(env)}/logos/${brandCode}.svg`;

type SvgStyleProps = {
  size?: number;
  height?: number;
  width?: number;
};

export const useSvgStyle = ({ size = 0, width = 0, height = 0 }: SvgStyleProps) => {
  const h = size || height;
  const w = size || width;
  return useMemo(
    () => ({ height: `${h}px`, width: `${w}px`, marginTop: 5, marginInlineEnd: 19 }),
    [h, w]
  );
};
