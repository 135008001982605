import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';
import { useState } from 'react';

import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { BrandCarousel } from './BrandCarousel';
import { useSegmentedItems } from '../../hooks/use-segments';
import { extractInstanceIndexFromId } from '../../utils';

import { BrandCarouselEditorButtons } from './BrandCarouselEditorButtons';

export default createCpmComponentDefinition(
  'Carousel',

  function mapData({ data }) {
    const asset = data.cpmAssets[0];

    if (!asset) {
      throw new Error(`${data.displayName || data.name} is missing an image`);
    }

    return {
      $ref: data.ref?.$ref,
      id: data.id ?? '',
      image: {
        alt: asset.altText,
        url: asset.aspectRatios['3x2']?.url ?? '',
        captionData: asset.caption
          ? {
              captionLink: asset.captionLink ?? undefined,
              caption: asset.caption ?? '',
            }
          : undefined,
      },
      headline: data.headline,
      description: data.longDescription,
      link: data.link?.url ? data.link : null,
      segmentIds: data.segmentIds,
      cmsTranslationClasses: data.cmsTranslationClasses,
    };
  },

  function BrandCarouselCpm({
    items = [],
    listData,
    componentParams,
    metrics,
    mappedPage: { brandCode },
    isCPMEditor,
  }) {
    const [slideIndex, setSlideIndex] = useState(0);
    const { textAlign, noMargin } = componentParams;

    const createManageContentButton = useCreateManageContentButton();

    const id = listData?.id ?? '';

    const filteredItems = useSegmentedItems(items);

    if (!filteredItems.length) {
      return null;
    }

    const itemsWithManageContentButton = filteredItems.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const onArrowClick = (newIndex: number) => {
      setSlideIndex(newIndex);

      if (metrics.trackCarouselNavigationClick) {
        metrics.trackCarouselNavigationClick([
          {
            navigationClick: true,
            carouselNumber: extractInstanceIndexFromId(id),
            index: newIndex + 1,
            totalDots: items.length,
          },
        ]);
      }
    };

    const images = filteredItems.map((item) => item.image);

    return (
      <BrandComponentThemeInline
        brandCode={brandCode}
        componentClassName="brandCarousel"
        componentParams={componentParams}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <BrandCarousel
          items={itemsWithManageContentButton}
          isFullScreen={noMargin}
          images={images}
          onArrowClick={onArrowClick}
          alignContent={textAlign}
          brandComponentTheme={componentParams.theme}
        />
        <BrandCarouselEditorButtons
          isCPMEditor={isCPMEditor}
          items={itemsWithManageContentButton}
          slideIndex={slideIndex}
        />
      </BrandComponentThemeInline>
    );
  }
);
