import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';
import { useId } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { extractInstanceIndexFromId } from '../../utils';
import { useSegmentedItems } from '../../hooks/use-segments';
import { applyNHCBP5717Conductrics } from '../../utils/conductrics';
import { useTabsHHVideoContentABTest } from '../../hooks/use-tabs-hh-video-content-ab-test';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { mapMultimediaListToVideoProps } from '../../utils/video';
import { HorizontalTabbedSection } from './HorizontalTabbedSection';
import { VerticalTabbedSection } from './VerticalTabbedSection';
import { Status } from '@dx-ui/framework-conductrics';

export default createCpmComponentDefinition(
  'Tabbed Section',

  function mapData({ data, mappedPage }) {
    const { link, id, label, headline, shortDescription, longDescription, logoUrl, iconType } =
      data;
    const videoData = data.videos?.[0];
    const multimediaList = data.multimedia;
    const asset = data.cpmAssets[0];
    const imageUrl = asset?.aspectRatios?.['3x2']?.url;
    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;
    const video = videoData
      ? {
          source: videoData.source || '',
          title: videoData?.name || '',
          url: videoData.url,
        }
      : undefined;
    const multimedia = multimediaList?.length
      ? mapMultimediaListToVideoProps(multimediaList, mappedPage)
      : undefined;
    const hasImage = imageUrl && asset?.altText;
    const hasVideo = multimedia?.videoUrl || video?.url;

    return {
      $ref: data.ref?.$ref,
      id,
      segmentIds: data.segmentIds,
      ...(logoUrl && { logoUrl }),
      ...(iconType && { iconType }),
      tab: {
        label,
      },
      panel: {
        media: {
          multimedia,
          video,
        },
        image:
          hasImage && !hasVideo
            ? {
                alt: asset?.altText || '',
                url: imageUrl ?? '',
                captionData,
              }
            : undefined,
        headline,
        copy: shortDescription,
        longDescription: longDescription || undefined,
        link: {
          label: link?.label ?? '',
          url: link?.url ?? '',
          adaDescription: link?.adaDescription ?? '',
          isNewWindow: link?.isNewWindow,
        },
      },
      cmsTranslationClasses: data.cmsTranslationClasses,
    };
  },

  function TabbedSection({
    items,
    listData: data,
    componentParams,
    metrics,
    mappedPage: { brandCode, localeCode },
  }) {
    const id = useId();
    const { tabbedSectionDisplay } = componentParams;
    /**
     * start of https://jira.hilton.com/browse/NHCBP-5717 code (to be removed after AB test results)
     */
    const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
      threshold: [0.4],
      freezeOnceVisible: true,
    });

    const { shouldDisplayVideo } = useTabsHHVideoContentABTest(inView ? Status.OK : Status.PENDING);
    const unfilteredItems =
      items && tabbedSectionDisplay === 'horizontal'
        ? applyNHCBP5717Conductrics(items, shouldDisplayVideo)
        : items;
    /**
     * end of https://jira.hilton.com/browse/NHCBP-5717 code (to be removed after AB test results)
     */

    const filteredItems = useSegmentedItems(unfilteredItems || []);
    const createManageContentButton = useCreateManageContentButton();

    if (!filteredItems.length) {
      return null;
    }
    const itemsWithManageContentButton = filteredItems.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item?.$ref),
    }));

    const onTabChange = (tabId: string) => {
      /**
       * start of https://jira.hilton.com/browse/NHCBP-5717 code (to be removed after AB test results)
       */
      if (tabId) {
        const targetTabIndex = filteredItems.findIndex((item) => item.id === tabId);
        const tab: { label: string; onTabChange?: () => void } =
          filteredItems?.[targetTabIndex]?.tab;
        tab?.onTabChange?.();
      }
      /**
       * end of https://jira.hilton.com/browse/NHCBP-5717 code (to be removed after AB test results)
       */

      if (metrics && tabId) {
        const targetTabIndex = filteredItems.findIndex((item) => item.id === tabId);
        const label = filteredItems?.[targetTabIndex]?.tab?.label;
        metrics.trackTabItemClick?.([
          {
            label,
            tabComponentNumber: extractInstanceIndexFromId(id),
            itemNumber: targetTabIndex + 1,
            totalItems: itemsWithManageContentButton.length,
          },
        ]);
      }
    };

    return (
      <BrandComponentThemeInline
        brandCode={brandCode}
        componentParams={componentParams}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        {tabbedSectionDisplay === 'vertical' ? (
          <VerticalTabbedSection
            listDescription={data?.description ?? undefined}
            listHeadline={data?.headline ?? undefined}
            items={itemsWithManageContentButton}
            brandComponentTheme={componentParams.theme}
            id={id}
          />
        ) : (
          <HorizontalTabbedSection
            listDescription={data?.description ?? undefined}
            isFull={false}
            listHeadline={data?.headline ?? undefined}
            id={id}
            items={itemsWithManageContentButton}
            defaultTab={filteredItems[0].id}
            onTabChange={onTabChange}
            brandComponentTheme={componentParams.theme}
            localeCode={localeCode}
            wrapperRef={intersectionRef}
          />
        )}
      </BrandComponentThemeInline>
    );
  }
);
