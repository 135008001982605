import cx from 'classnames';

export type TextBlockHeaderType = {
  children?: React.ReactNode;
  ariaLabel?: string;
  className?: string;
  id?: string;
};

/**
 * Extra, Extra! Announcing the Text Block Header, consumed by other components to create a bold headline.
 */
export const TextBlockHeader = ({ children, ariaLabel, className = '', id }: TextBlockHeaderType) =>
  children ? (
    <h2
      className={cx(className, 'heading-2xl lg:mb-2 lg:heading-4xl')}
      data-testid="textBlockHeader"
      id={id}
    >
      {children}

      {ariaLabel && <span className="sr-only">{ariaLabel}</span>}
    </h2>
  ) : null;

export default TextBlockHeader;
