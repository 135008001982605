import type * as React from 'react';

import { TextBlock } from '@dx-ui/osc-text-block';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';

import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { MarkdownBlock } from './MarkdownBlock';
import { useSegmentedItems } from '../../hooks/use-segments';

export type ParsedContentSection = Exclude<
  React.ComponentProps<typeof TextBlock>['data'],
  undefined
>[number];

export default createCpmComponentDefinition(
  'Text Component',

  function mapData({ data, cmsDocumentType, addIssue, clearIssue }) {
    if (data.markdownEditor) {
      return {
        $ref: data.ref?.$ref,
        segmentIds: data.segmentIds,
        markdown: data.markdownEditor,
      } as { $ref: string; segmentIds: string[]; markdown: string };
    }

    const includeLinks = cmsDocumentType === 'Story';

    const mappedContentBlocks: Array<ParsedContentSection | null> = (data.contentBlock ?? []).map(
      (section): ParsedContentSection | null => {
        if (section?.description) {
          return { kind: 'description', content: section.description };
        }
        if (section?.heading) {
          return { kind: 'heading', content: section.heading };
        }
        if (section?.orderedList) {
          return { kind: 'orderedList', content: section.orderedList };
        }
        if (section?.unorderedList) {
          return { kind: 'unorderedList', content: section.unorderedList };
        }

        return null;
      }
    );

    if (includeLinks && data?.links?.length) {
      const link = data.links[0];

      mappedContentBlocks.push({
        kind: 'cta',
        content: {
          _id: '',
          url: link.url,
          label: link.label,
          adaDescription: link.adaDescription || '',
          isNewWindow: link.isNewWindow || false,
        },
      });
    }

    const blocks = mappedContentBlocks.filter((section): section is ParsedContentSection =>
      Boolean(section)
    );

    if (!blocks.length) {
      addIssue({
        id: data.id,
        message: `"${data.name || data.displayName}" has no content attached`,
      });
    } else {
      clearIssue(data.id);
    }

    return {
      $ref: data.ref?.$ref,
      segmentIds: data.segmentIds,
      blocks,
    };
  },

  function TextBlockCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items);

    const { textAlign, animation, theme, borderTrim } = componentParams;

    if (!filteredItems.length) {
      return null;
    }

    const data = filteredItems[0];

    if ('blocks' in data) {
      if (!data?.blocks.length) {
        return null;
      }

      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <TextBlock
            textAlign={textAlign}
            isAnimated={animation}
            isBorderTrim={borderTrim}
            data={data.blocks}
            brandComponentTheme={theme}
          />
        </BrandComponentThemeInline>
      );
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <MarkdownBlock
          textAlign={textAlign}
          isAnimated={animation}
          isBorderTrim={borderTrim}
          markdown={data.markdown}
          brandComponentTheme={theme}
        />
      </BrandComponentThemeInline>
    );
  }
);
