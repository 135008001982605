import { Link } from '@dx-ui/osc-link';
import type { Link as LinkType } from '@dx-ui/osc-link';
import { Icon } from '@dx-ui/osc-icon';
import type { IconNames } from '@dx-ui/osc-icon';
import { TextBlockBody } from '@dx-ui/osc-marketing';
import Image from 'next/image';
import cx from 'classnames';

type EditorialSnippetType = {
  heading: string;
  longDescription: string;
  shortDescription?: string;
  socialMediaLink?: LinkType;
  socialMediaIcon?: (typeof IconNames)[number];
  ctaLink?: LinkType;
  image?: {
    url?: string;
    altText?: string;
  };
  /** Image is round by default, CMS can select for square image */
  isSquareImage?: boolean;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const EditorialSnippet = ({
  heading,
  longDescription,
  shortDescription,
  socialMediaLink,
  socialMediaIcon,
  ctaLink,
  image,
  isSquareImage,
  brandComponentTheme,
}: EditorialSnippetType) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div className="container relative">
      <div
        className={cx('editorial-snippet-bg py-4 lg:px-20 sm:py-12', {
          'editorial-snippet-bg-dark': isDark,
          'editorial-snippet-bg-light': isLight,
        })}
        data-testid="testContainerBackgroundCss"
      >
        <div
          className={cx('relative flex flex-col justify-center sm:self-center', {
            'sm:ps-56': image?.url,
          })}
        >
          <hgroup>
            <h2
              className={cx('heading-xl sm:heading-2xl lg:heading-3xl flex-col', {
                '!text-text-inverse': isDark,
                'editorial-snippet-headline-light': isLight,
              })}
            >
              {heading}
            </h2>
            {shortDescription ? (
              <TextBlockBody
                className={cx(
                  'editorial-snippet-short-description lg:editorial-snippet-short-description-lg',
                  {
                    'editorial-snippet-short-description-dark': isDark,
                    'editorial-snippet-short-description-light': isLight,
                  }
                )}
                brandComponentTheme={brandComponentTheme}
              >
                {shortDescription}
              </TextBlockBody>
            ) : null}
          </hgroup>

          <TextBlockBody
            className={cx('text-base font-normal text-text lg:!text-lg', {
              'text-text-inverse': isDark,
              'editorial-snippet-text-body-light': isLight,
            })}
            brandComponentTheme={brandComponentTheme}
          >
            {longDescription}
          </TextBlockBody>

          {image?.altText && image?.url ? (
            <div className="relative order-first mb-6 aspect-square size-44 sm:absolute sm:start-0 sm:top-0 sm:mb-0">
              <Image
                alt={image?.altText}
                src={image?.url}
                className={cx('object-cover rounded-full', {
                  'rounded-none': isSquareImage === true,
                })}
                fill
              />
            </div>
          ) : null}

          <div>
            {socialMediaLink?.url ? (
              <Link
                isNewWindow={true}
                url={socialMediaLink?.url}
                showNewWindowIcon={true}
                underline={false}
                className={cx(
                  'editorial-snippet-link inline-block items-baseline pe-7 pb-4 lg:pb-0',
                  {
                    'editorial-snippet-link-dark': isDark,
                    'editorial-snippet-link-light': isLight,
                  }
                )}
              >
                {socialMediaIcon ? (
                  <div className="inline-block pe-1.5">
                    <Icon name={socialMediaIcon} size="lg" className="-mb-2 me-1" />
                  </div>
                ) : null}

                <div className="inline pe-1 align-baseline underline underline-offset-4">
                  {socialMediaLink?.label}
                </div>
              </Link>
            ) : null}

            {ctaLink?.url ? (
              <Link
                isNewWindow={ctaLink?.isNewWindow || false}
                url={ctaLink?.url}
                showNewWindowIcon={ctaLink?.isNewWindow || false}
                underline={false}
                className={cx('editorial-snippet-link items-baseline', {
                  'editorial-snippet-link-dark': isDark,
                  'editorial-snippet-link-light': isLight,
                })}
              >
                <div className="inline pe-1 underline underline-offset-4">{ctaLink?.label}</div>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorialSnippet;
