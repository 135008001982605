import type {
  AssetVariantNames,
  BrPage,
  ImagePointer,
  OscDomLink,
  AddCampaignCodeToUrl,
} from '../adapters/types';
import type {
  CmsDataTypesIntersection,
  MappedComponent,
  CmsUnknownComponentParams,
} from '../schema';
import { getAssetImage, getFromPageByRef, getImageCompound } from '../adapters/getters';
import { objectMapWithKey } from '../helpers';
import { translateLink } from './translators';
import { compoundImageBreakpointSizes } from './compound-image-breakpoint-sizes';
import { getBrandCodeFromPage } from '../mappingEngine/getBrandCodeFromPage';
import { removeNullyFromArray } from '../utils/remove-nully-from-array';

type WithRefAsDefaultId<T extends CmsDataTypesIntersection> = T & {
  id: string;
  _id: null;
};
export function refAsDefaultIdEnhancer<T extends CmsDataTypesIntersection>(
  data: T
): WithRefAsDefaultId<T> {
  // Use ref as a fallback if item.id is undefined
  let ref = '';

  if (typeof data.ref === 'string') {
    ref = data.ref;
  } else if (typeof data.ref?.$ref === 'string') {
    ref = data.ref.$ref;
  }

  // replace any "/" with a "-" because the ref is used in the html id attribute
  ref = ref.replace(/\//g, '-');

  return {
    ...data,

    id: data.id || ref,
    _id: null,
  };
}

type WithRenamedBrandcode<T extends CmsDataTypesIntersection> = Omit<T, 'brandCode'> & {
  documentBrandCode?: string;
};
export function renameBrandcodeEnhancer<T extends CmsDataTypesIntersection>(
  data: T
): WithRenamedBrandcode<T> {
  // Rename the brandCode key and delete it to avoid overwriting the channel brand code
  // on components.
  // Components that need the brand code associated with the document can use the
  // `documentBrandCode` key instead.

  const { brandCode, ...rest } = data;
  if (brandCode) {
    return {
      ...rest,
      documentBrandCode: brandCode,
    };
  } else {
    return rest;
  }
}

type WithTranslatedLinks<T extends CmsDataTypesIntersection> = Omit<T, 'links' | 'link'> & {
  links: OscDomLink[];
  link: OscDomLink | null;
};
export function translateLinksEnhancer<T extends CmsDataTypesIntersection>(
  data: T,
  addCampaignCodeToUrl: AddCampaignCodeToUrl
): WithTranslatedLinks<T> {
  if ('links' in data) {
    return {
      ...data,
      links: removeNullyFromArray(
        data.links?.map((link) => translateLink(link, addCampaignCodeToUrl)) ?? []
      ),
      link: translateLink(data.links?.[0] ?? null, addCampaignCodeToUrl),
    };
  }

  // Award doc types incorrectly have a link property instead of the standard links array
  if (!('links' in data) && 'link' in data) {
    const singleLink = translateLink(data.link ?? null, addCampaignCodeToUrl);

    return {
      ...data,
      links: removeNullyFromArray(singleLink ? [singleLink] : []),
      link: singleLink,
    };
  }

  return {
    ...data,
    links: [],
    link: null,
  };
}

type WithCmsComponentDefaultFields<T extends CmsDataTypesIntersection> = T & {
  headline: string;
  shortDescription: string;
  caption: string;
  label: string;
  segmentIds: string[];
};
export function addDefaultCmsComponentFieldsEnhancer<T extends CmsDataTypesIntersection>(
  data: T
): WithCmsComponentDefaultFields<T> {
  return {
    ...data,
    caption: '',
    headline: 'headline' in data ? data.headline ?? '' : '',
    label: 'name' in data ? data.name ?? '' : '',
    segmentIds: data.segmentIds ?? [],
    shortDescription: 'shortDescription' in data ? data.shortDescription ?? '' : '',
  };
}

export type ImageAsset = {
  id: string;
  url: string;
};

export type StructuredAsset = {
  id: string;
  brandCodes: string[];
  altText: string;
  caption: string;
  captionLink: string;
  aspectRatios: Partial<Record<AssetVariantNames, ImageAsset>>;
};

type WithAssets<Data extends CmsDataTypesIntersection> = Data & {
  cpmAssets: (StructuredAsset | undefined)[];
};

function generateStructuredAssetFromBloomreachAsset(
  imagePointer: ImagePointer,
  page: BrPage,
  componentSchema: MappedComponent
): StructuredAsset | null {
  const { brandCodes = [] } = imagePointer;
  const resolvedAsset = getAssetImage(
    imagePointer,
    page,
    componentSchema.assets as readonly AssetVariantNames[]
  );

  if (resolvedAsset === null) {
    return null;
  }

  const { variants, ...bloomreachAsset } = resolvedAsset;

  const aspectRatios = objectMapWithKey(variants, (aspectRatio, assetAtRatio) => {
    const url = new URL(assetAtRatio.cdnLink);

    const [aspectWidth, aspectHeight] = aspectRatio.split('x').map(Number);
    const aspectRatioAsFloat = aspectWidth / aspectHeight;

    const largestPossibleDimension = componentSchema.namedImages
      .flatMap((namedImage) => Object.values(compoundImageBreakpointSizes[namedImage]))
      .filter(
        (compoundImageBreakpointSize) => compoundImageBreakpointSize.aspectRatio === aspectRatio
      )
      .map(({ sizes }) => sizes)
      .reduce((acc, val) => Math.max(acc, val.height, val.width), 0);

    const isLandscape = aspectRatioAsFloat > 1.0;

    const height = isLandscape
      ? largestPossibleDimension / aspectRatioAsFloat
      : largestPossibleDimension;
    const width = isLandscape
      ? largestPossibleDimension
      : largestPossibleDimension * aspectRatioAsFloat;

    if (!(url.searchParams.has('rw') && url.searchParams.has('rh'))) {
      url.searchParams.set('rw', Math.ceil(width) + '');
      url.searchParams.set('rh', Math.ceil(height) + '');
    }

    return {
      id: assetAtRatio.id,
      url: url.toString(),
    };
  });

  return {
    ...bloomreachAsset,
    aspectRatios,
    brandCodes,
  };
}

/**
  Old Style images can have many possible "variants", each of which contain separate metadata
  instances (eg: id, caption) and URLs pointing to images of different aspect ratios.

  We traverse along every variant we find, selecting the metadata instances and keeping only a single copy for each prop
  We also keep only a single image URL for each aspect ratio.
*/
function generateStructuredAssetFromOldStyleImage(
  imagePointer: ImagePointer,
  page: BrPage,
  componentSchema: MappedComponent
): StructuredAsset | null {
  const { brandCodes = [] } = imagePointer;

  const oldStyleImages = componentSchema.namedImages.map((imageName) => ({
    imageName,
    imageCompound: getImageCompound(imagePointer, imageName, page),
  }));

  if (oldStyleImages.length === 0) {
    return null;
  }

  let id = '';
  let caption = '';
  let captionLink = '';
  let altText = '';
  const aspectRatios: Partial<Record<AssetVariantNames, ImageAsset>> = {};

  for (const { imageName, imageCompound } of oldStyleImages) {
    if (imageCompound === null) {
      continue;
    }

    id ||= imageCompound._id;
    caption ||= imageCompound.caption;
    captionLink ||= imageCompound.captionLink;
    altText ||= imageCompound.image.altText;

    for (const size of ['xs', 'sm', 'md'] as const) {
      const variant = imageCompound.image?.variants.find((variant) => variant.size === size);

      if (variant) {
        const { url, _id } = variant;
        const { aspectRatio } = compoundImageBreakpointSizes[imageName][size];

        aspectRatios[aspectRatio] = { url, id: _id };
      }
    }
  }

  return {
    id,
    caption,
    captionLink,
    altText,
    aspectRatios,
    brandCodes,
  };
}

export function makeAddAssetsEnhancer<ComponentSchema extends MappedComponent>(
  componentSchema: ComponentSchema,
  page: BrPage
) {
  return function addAssetsEnhancer<Data extends CmsDataTypesIntersection>(data: Data) {
    // Bail out because image data doesn't exist or component has no aspect ratio images (assets) defined
    if (
      (!('images' in data) && !('defaultPropertyImages' in data)) ||
      !componentSchema.assets.length
    ) {
      const cpmAssets: StructuredAsset[] = [];
      return { ...data, cpmAssets };
    }

    const cpmAssets: StructuredAsset[] = [];

    /**
        `data.images` contains an array of references to Bloomreach Image documents
        Each of these Image documents can contain both "images" and "assets".

        Certain document types (eg: brands) use data.defaultPropertyImages instead of data.images.

        "images" are the old way of specifying images, they contain various "variants"
        that specify What They're Used For (eg: "collage", "fourXExpansionPanel").

        "assets" are the new way of specifying image, they contain various "aspect ratios"
        that specify the cropping of an image asset (eg: "1x1", "3x2").

        We prefer to use "assets", but will fall back to using "images". In both cases we do
        some pre-processing to ensure the data is the same shape and required no further logic to use.

        A document can contain multiple images, which will be used in difference places in the UI.
        Eg: Collage can contain several different images per component
    */
    for (const imageRef of data?.images ?? data?.defaultPropertyImages ?? []) {
      const structuredAsset =
        generateStructuredAssetFromBloomreachAsset(imageRef, page, componentSchema) ??
        generateStructuredAssetFromOldStyleImage(imageRef, page, componentSchema);

      if (structuredAsset) {
        cpmAssets.push(structuredAsset);
      }
    }

    // if one of the images has a brandCode assigned that matches the current page brandCode
    // it should come first
    const pageBrandCode = getBrandCodeFromPage(page.toJSON());
    cpmAssets.sort((l, r) => {
      const lMatchesBrandCode = l.brandCodes.includes(pageBrandCode) ? 1 : 0;
      const rMatchesBrandCode = r.brandCodes.includes(pageBrandCode) ? 1 : 0;
      return rMatchesBrandCode - lMatchesBrandCode;
    });

    return {
      ...data,
      cpmAssets,
    };
  };
}

type DocumentMeta = {
  $ref: string;
  cbId: string;
};

export type RelatedDocument = {
  _meta: DocumentMeta;
  headline?: string;
  shortDescription?: string;
  longDescription?: string;
  link: OscDomLink | null;
  links: OscDomLink[];
  cpmAssets: StructuredAsset[];
  segmentIds: string[];
};

export type WithRelatedDocuments<Data extends CmsDataTypesIntersection> = Data & {
  cpmRelatedDocuments: (RelatedDocument | undefined)[];
};

export function addRelatedDocsEnhancer<Data extends CmsDataTypesIntersection>(
  data: Data,
  page: BrPage,
  addAssetsEnhancer: ReturnType<typeof makeAddAssetsEnhancer>,
  addCampaignCodeToUrl: AddCampaignCodeToUrl
) {
  const cpmRelatedDocuments: RelatedDocument[] = [];

  if (!data.relatedDocumentsRef || !Array.isArray(data.relatedDocumentsRef)) {
    return { ...data, cpmRelatedDocuments };
  }

  if (!data.relatedDocuments || !Array.isArray(data.relatedDocuments)) {
    return { ...data, cpmRelatedDocuments };
  }

  // First collate the refs and couchbase ids together
  const documentMetas = removeNullyFromArray(
    data.relatedDocumentsRef.map((item, index) => {
      if (!item.$ref || !data?.relatedDocuments?.[index]) {
        return null;
      }

      const meta: DocumentMeta = {
        $ref: item.$ref,
        cbId: data.relatedDocuments[index],
      };

      return meta;
    })
  );

  // Now we can hydrate each document
  documentMetas.forEach((_meta) => {
    const document = getFromPageByRef<CmsDataTypesIntersection>(_meta.$ref, page);

    if (!document) {
      return;
    }

    const { cpmAssets } = addAssetsEnhancer(document);
    const { link, links } = translateLinksEnhancer(document, addCampaignCodeToUrl);

    const enhancedDoc: RelatedDocument = {
      _meta,
      headline: document.headline ?? undefined,
      shortDescription: document.shortDescription ?? undefined,
      longDescription: document.longDescription ?? undefined,
      segmentIds: document.segmentIds ?? [],
      links,
      link,
      cpmAssets,
    };

    cpmRelatedDocuments.push(enhancedDoc);
  });

  return { ...data, cpmRelatedDocuments };
}

export type WithTranslationCssClasses<Data extends CmsDataTypesIntersection> = Data & {
  cmsTranslationClasses?: string;
};
export function translationCssClassesEnhancer<Data extends CmsDataTypesIntersection>(
  data: Data,
  componentParams?: CmsUnknownComponentParams,
  documentNumber?: number
): WithTranslationCssClasses<Data> {
  if (!componentParams || !documentNumber) {
    return data;
  }

  const itemExcludedFromTranslation = componentParams[`oneLinkNoTx${documentNumber}`] === true;

  if (!itemExcludedFromTranslation) {
    return data;
  }

  return {
    ...data,
    cmsTranslationClasses: 'OneLinkNoTx',
  };
}

export type EnhancedCmsComponentDocument = WithTranslatedLinks<
  WithRenamedBrandcode<
    WithTranslationCssClasses<
      WithRelatedDocuments<
        WithAssets<WithCmsComponentDefaultFields<WithRefAsDefaultId<CmsDataTypesIntersection>>>
      >
    >
  >
>;

/**
 * Chains together the above enhancer functions, you must remember to update the above type if you change this enhancer composition
 */
export function makeCmsComponentDocumentEnhancer<ComponentSchema extends MappedComponent>(
  componentSchema: ComponentSchema,
  page: BrPage,
  addCampaignCodeToUrl: AddCampaignCodeToUrl
) {
  const addAssetsEnhancer = makeAddAssetsEnhancer<ComponentSchema>(componentSchema, page);

  return (
    data: CmsDataTypesIntersection,
    componentParams?: CmsUnknownComponentParams,
    documentNumber?: number
  ): EnhancedCmsComponentDocument => {
    const step1 = refAsDefaultIdEnhancer(data);
    const step2 = addDefaultCmsComponentFieldsEnhancer(step1);
    const step3 = addAssetsEnhancer(step2);
    const step4 = addRelatedDocsEnhancer(step3, page, addAssetsEnhancer, addCampaignCodeToUrl);
    const step5 = translationCssClassesEnhancer(step4, componentParams, documentNumber);
    const step6 = renameBrandcodeEnhancer(step5);
    const step7 = translateLinksEnhancer(step6, addCampaignCodeToUrl);

    return step7 as EnhancedCmsComponentDocument;
  };
}
