import { ParallaxProvider } from 'react-scroll-parallax';
import Cookies from 'universal-cookie';
import type { ContainerItem } from '@bloomreach/spa-sdk';
import { TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';
import * as React from 'react';
import type { MetricsInterface } from '@dx-ui/cpm-metrics';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { MappingContextProvider, useMappingContext } from './context';
import type {
  CpmDataBundle,
  CpmComponentDefinition,
  KeyedMappingDefinition,
  CpmMappedComponentData,
  CpmPreMappedDataInstance,
} from './types';
import { useBrComponentContext } from '../adapters/hooks';
import type { BrMapping, ExtractCampaignCodeTaggingValuesFn } from '../adapters/types';
import EditWrapper from '../components/EditWrapper';
import Fallback from '../components/Fallback';
import { OneLinkWrapper } from '../components/OneLinkWrapper';
import { useIssues } from '../hooks/use-issues';
import type { MappedComponentName } from '../schema';
import { mappingSchema } from '../schema';
import { mapCpmData } from './mapCpmData';
import { BrPage, BrComponent } from '../adapters/components';
import { CookiesContext } from '../context/CookiesContext';
import { getIsReducedMotion } from '../utils/get-is-reduced-motion';
import { getTaggingValuesFromCpmAnalytics } from '../utils/add-campaign-id-to-url';
import { useRouter } from 'next/router';

import type { BrProps } from '@bloomreach/react-sdk';
import { CpmQueryClientProvider, CpmDataWrapper } from './DataWrapper';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';

export type CpmRendererProps<Definitions extends KeyedMappingDefinition> = {
  cpmData: CpmDataBundle<Definitions>;
  sectionNames: Array<string>;
  metrics: Partial<MetricsInterface>;
  children: (layout: Record<string, React.ReactNode>) => React.ReactNode;
  campaignCodeTaggingValues?: ExtractCampaignCodeTaggingValuesFn;
  mappingName?: string;
  isCPMEditor?: boolean;
};

function makeCpmMappingComponent<ComponentName extends MappedComponentName, MappingOutput>(
  definition: CpmComponentDefinition<ComponentName, MappingOutput>,
  campaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn,
  isCPMEditor = false
) {
  const { component: RenderComponent, cpmComponentName, mapData } = definition;
  const componentSchema = mappingSchema[cpmComponentName];

  const CpmMapper = () => {
    const pageBr = useCpmMergedBrPageContext();
    const componentBr = useBrComponentContext();
    const issuesCallbacks = useIssues();
    const { mappedPage } = useMappingContext();

    const props: CpmMappedComponentData<ComponentName, MappingOutput> = mapCpmData<
      ComponentName,
      MappingOutput
    >(
      cpmComponentName,
      mapData,
      pageBr,
      componentBr,
      mappedPage,
      issuesCallbacks,
      campaignCodeTaggingValues,
      isCPMEditor
    );

    // Convert `keyedItems` object back to the `items` array for component mapping
    if ('keyedItems' in props) {
      props.items = Object.values(props.keyedItems);
    }

    return (
      <OneLinkWrapper componentParams={props.componentParams}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <RenderComponent {...(props as any)} metrics={{}} isCPMEditor={isCPMEditor} />
      </OneLinkWrapper>
    );
  };

  return function CpmMappingWrapper(props: BrProps<ContainerItem>) {
    return (
      <CpmDataWrapper {...props} componentName={definition.cpmComponentName}>
        <EditWrapper
          displayName={definition.cpmComponentName}
          isCPMEditor={isCPMEditor}
          isEditable={componentSchema.isEditable}
          isMappedComponent={componentSchema.mappingKind !== 'Unmapped'}
        >
          <CpmMapper />
        </EditWrapper>
      </CpmDataWrapper>
    );
  };
}

const UniversalProviders = ({ children }: { children: React.ReactNode }) => (
  <ParallaxProvider isDisabled={getIsReducedMotion()}>
    <CookiesContext.Provider value={new Cookies()}>{children}</CookiesContext.Provider>
  </ParallaxProvider>
);

export function makeCpmRenderer<Definitions extends KeyedMappingDefinition>(
  definitions: Partial<Definitions>
) {
  return function CpmRenderer({
    cpmData,
    children,
    sectionNames,
    mappingName,
    metrics,
    campaignCodeTaggingValues = getTaggingValuesFromCpmAnalytics,
    isCPMEditor,
  }: CpmRendererProps<Definitions>) {
    const router = useRouter();

    if ('cpmPage' in cpmData) {
      const mapping: BrMapping = {
        ...Object.fromEntries(
          Object.values(definitions).map((definition) => [
            definition.cpmComponentName,
            makeCpmMappingComponent<
              typeof definition.cpmComponentName,
              ReturnType<typeof definition.mapData>
            >(
              definition as CpmComponentDefinition<
                typeof definition.cpmComponentName,
                ReturnType<typeof definition.mapData>
              >,
              campaignCodeTaggingValues,
              isCPMEditor
            ),
          ])
        ),
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        [TYPE_CONTAINER_ITEM_UNDEFINED]: (props: any) => (
          <Fallback {...props} mappingName={mappingName} isCPMEditor={isCPMEditor} />
        ),
      };

      return (
        <UniversalProviders>
          <MappingContextProvider mappedPage={cpmData.mappedPage}>
            <CpmQueryClientProvider token={router.query?.token as string}>
              <BrPage
                page={cpmData.cpmPage}
                configuration={cpmData.bloomReachConfiguration}
                mapping={mapping}
              >
                {children(
                  Object.fromEntries(
                    sectionNames.map((sectionName) => [
                      sectionName,
                      <BrComponent key={sectionName} path={sectionName} />,
                    ])
                  )
                )}
              </BrPage>
            </CpmQueryClientProvider>
          </MappingContextProvider>
        </UniversalProviders>
      );
    } else {
      const { preMappedData } = cpmData;

      //eslint-disable-next-line no-inner-declarations
      function composePreMappedData(section: string): React.ReactNode {
        return (
          <React.Fragment>
            {Object.values(preMappedData[section]).map(
              (
                {
                  componentName,
                  mappedData,
                }: CpmPreMappedDataInstance<MappedComponentName, Definitions>,
                i: number
              ) => {
                const componentDefinition = definitions[componentName];

                if (componentDefinition) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const RenderComponent = (componentDefinition as any).component;

                  let items = 'items' in mappedData ? mappedData.items || [] : [];

                  // Convert `keyedItems` object back to the `items` array for component mapping
                  if ('keyedItems' in mappedData && mappedData.keyedItems) {
                    items = Object.values(mappedData.keyedItems);
                  }

                  return (
                    // eslint-disable-next-line react/no-array-index-key -- This data will come from the server, it will never change, we're safe to use indexes as keys
                    <ErrorBoundary showError={false} key={i}>
                      <OneLinkWrapper componentParams={mappedData.componentParams}>
                        <RenderComponent {...mappedData} items={items} metrics={metrics} />
                      </OneLinkWrapper>
                    </ErrorBoundary>
                  );
                } else {
                  return null;
                }
              }
            )}
          </React.Fragment>
        );
      }

      return (
        <UniversalProviders>
          <MappingContextProvider mappedPage={cpmData.mappedPage}>
            {children(
              Object.fromEntries(
                sectionNames.map((sectionName) => [sectionName, composePreMappedData(sectionName)])
              )
            )}
          </MappingContextProvider>
        </UniversalProviders>
      );
    }
  };
}
