import { createElement } from 'react';

interface HType extends React.HTMLAttributes<HTMLHeadingElement> {
  level: HeadingLevel;
  children: React.ReactNode;
}

export type HeadingLevel = `h1` | `h2` | `h3` | `h4` | `h5` | `h6`;

const headingLevels: HeadingLevel[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

/**
 * Render a heading element, based on the provided heading level.
 *
 * @returns An HTML element, from <h1> to <h6>
 */
export const H: React.FC<HType> = ({ level, children, ...props }) => {
  const h = headingLevels.includes(level) ? level : 'h1';
  return createElement(h, props, children);
};
