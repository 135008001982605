import type { Page } from '@bloomreach/spa-sdk';
import { initialize } from '@bloomreach/spa-sdk';
import type { QueryClient } from '@tanstack/react-query';

import type {
  BrComponent,
  BrPage,
  BrPageModel,
  CpmPageModel,
  ExtractCampaignCodeTaggingValuesFn,
} from '../adapters/types';
import { serverSideCmsPageModelQuery } from '../generated/queries';
import type {
  CpmPreMappedDataInstance,
  KeyedMappingDefinition,
  CpmMappingFunction,
  CpmServerBundle,
} from './types';
import { mapCpmData } from './mapCpmData';
import { getBrComponentName, isMappedComponentName, type MappedComponentName } from '../schema';
import { removeUndefineds } from '../helpers';
import { type CpmMappedPage, extractMappedPage } from './cpmMappedPage';
import { getPath } from '../utils/unknown-object-helpers';
import { getTaggingValuesFromCpmAnalytics } from '../utils/add-campaign-id-to-url';

export function makeFetchServerCpmPage<Definitions extends KeyedMappingDefinition>(
  definitions: Partial<Definitions>
) {
  function preMapComponent<ComponentName extends MappedComponentName>(
    componentName: ComponentName,
    componentBr: BrComponent,
    pageBr: BrPage,
    mappedPage: CpmMappedPage,
    extractCampaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn
  ): CpmPreMappedDataInstance<ComponentName, Definitions> {
    type MapData =
      | CpmMappingFunction<ComponentName, ReturnType<Definitions[ComponentName]['mapData']>>
      | undefined;

    const mapData = definitions[componentName]?.mapData as MapData;

    if (!mapData) {
      throw new Error(`No mapping provided for component "${componentName}"`);
    }

    const mappedData = mapCpmData(
      componentName,
      mapData,
      pageBr,
      componentBr,
      mappedPage,
      {
        add: () => null,
        clear: () => null,
      },
      extractCampaignCodeTaggingValues
    );

    return {
      componentName,
      mappedData,
    };
  }

  function preMapSection(
    pageBr: Page,
    mappedPage: CpmMappedPage,
    sectionName: string,
    extractCampaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn
  ): Record<string, CpmPreMappedDataInstance<MappedComponentName, Definitions>> {
    const brComponents = pageBr.getComponent(sectionName)?.getChildren() ?? [];

    return Object.fromEntries(
      brComponents
        .map(function applyDataMapping(componentBr) {
          let data: ReturnType<typeof preMapComponent<MappedComponentName>> | null = null;

          try {
            const componentName = getBrComponentName(componentBr);
            if (!isMappedComponentName(componentName)) {
              throw new Error(`Cannot map unknown component`);
            }

            data = preMapComponent<MappedComponentName>(
              componentName,
              componentBr,
              pageBr,
              mappedPage,
              extractCampaignCodeTaggingValues
            );
          } catch (error) {
            const [id, label, ref] = [
              getPath(componentBr, ['model', 'id']),
              getPath(componentBr, ['model', 'label']),
              getPath(componentBr, ['model', 'links', 'self', 'href']),
            ];

            // eslint-disable-next-line no-console
            console.warn(
              `Errored during premap for ${String(label)}. Document id: "${String(
                id
              )}" label: "${String(label)}" ref: "${String(ref)}"`
            );
          }

          return {
            isUntranslated: !!getPath(componentBr, ['model', 'meta', 'paramsInfo', 'oneLinkNoTx']),
            data,
          };
        })
        .map(function assignIndexedKeys({ isUntranslated, data }, i, { length }) {
          return [
            [
              'cpm-',
              i.toString().padStart(Math.ceil(Math.log10(length)) + 1, '0'),
              isUntranslated ? '-noTx' : '',
            ].join(''),
            data,
          ];
        })
        .filter((item) => item[1] !== null)
    );
  }

  return async function fetchServerCpmPage(
    queryClient: QueryClient,
    contentPath: string,
    localeCode: string,
    pathname: string,
    sectionNames: string[],
    extractCampaignCodeTaggingValues = getTaggingValuesFromCpmAnalytics
  ): Promise<CpmServerBundle<Definitions> | null> {
    const cmsPageModel: CpmPageModel = (
      await serverSideCmsPageModelQuery(queryClient, {
        path: contentPath,
        language: localeCode,
      })
    ).cmsPageModel;

    if (!cmsPageModel) {
      return null;
    }

    const cpmPageModel = cmsPageModel;

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bloomReachConfiguration: any = {
      httpClient: () => Promise.resolve({ data: cpmPageModel }),
    };

    const cpmPage = initialize(bloomReachConfiguration, cpmPageModel as BrPageModel);
    const mappedPage = extractMappedPage(pathname, localeCode, cpmPage);

    return removeUndefineds({
      contentPath,
      mappedPage,
      preMappedData: Object.fromEntries(
        sectionNames.map((sectionName) => [
          sectionName,
          preMapSection(cpmPage, mappedPage, sectionName, extractCampaignCodeTaggingValues),
        ])
      ),
    });
  };
}
