import type * as React from 'react';
import type { BrProps } from '@bloomreach/react-sdk';
import { BrManageContentButton } from '@bloomreach/react-sdk';

import { EditorIssuesProvider } from '../hooks/use-issues';
import { ErrorBoundary } from './ErrorBoundary';
import { useDataSingle } from '../hooks/use-data';
import { useHideManageContentButton } from '../hooks/use-hide-manage-content-button';
import { EditorIssues } from './EditorIssues';
import { NewContentBanner } from './NewContentBanner';
import { NullDocumentBanner } from './NullDocumentBanner';
import { useBrComponentContext } from '../adapters/hooks';
import { useCpmMergedBrPageContext } from '../context/CpmMergedBrPageContext';
import { SegmentationWrapper } from './SegmentationWrapper';

/**
 * Get a new ID when the parent/child document has changed
 * Note: This will be undefined in live mode because Core+
 * doesn't have ids
 */
const getEditorContentId = (data: null | { id?: string } | { id?: string }[]) => {
  if (!data) {
    return;
  }

  if (Array.isArray(data)) {
    return data
      .reduce((compound: string[], item) => {
        if (item?.id) {
          compound.push(item.id);
        }

        return compound;
      }, [])
      .join(':');
  } else if (data.id) {
    return data.id;
  }
};
export const EditWrapper = ({
  children,
  displayName,
  isCPMEditor,
  isEditable = true,
  isMappedComponent,
}: {
  children: React.ReactNode;
  displayName: string;
  isCPMEditor: boolean;
  isEditable?: boolean;
  isMappedComponent: boolean;
}) => {
  const hideManageContentButton = useHideManageContentButton();
  const page = useCpmMergedBrPageContext();
  const component = useBrComponentContext();

  const { document: documentRef } = component.getModels();

  const document = documentRef && page.getContent(documentRef);
  const data = useDataSingle();

  if (!document && isMappedComponent) {
    if (isCPMEditor) {
      // Document has been deleted but is still attached to component
      // which will stop the page publishing
      if (document === null) {
        return <NullDocumentBanner displayName={displayName} />;
      }

      return <NewContentBanner displayName={displayName} />;
    } else {
      return null;
    }
  }

  const wrappedChildren = (
    <ErrorBoundary
      // Force the ErrorBoundary component to reset when the document has changed in editor mode
      key={getEditorContentId(data)}
      displayName={displayName}
      showError={isCPMEditor}
      data={data}
    >
      {children}
    </ErrorBoundary>
  );

  if (!isEditable || !isMappedComponent) {
    return wrappedChildren;
  }

  return (
    <SegmentationWrapper>
      <EditorIssuesProvider isCPMEditor={isCPMEditor} key={getEditorContentId(data)}>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className={isCPMEditor ? 'has-edit-button' : ''}>
          {wrappedChildren}
          {isCPMEditor ? <EditorIssues id={`${data?.id}`} /> : null}
          {!hideManageContentButton ? (
            <BrManageContentButton content={document} parameter="document" relative />
          ) : null}
        </div>
      </EditorIssuesProvider>
    </SegmentationWrapper>
  );
};

export function withWrapper({
  Inner,
  displayName,
  isEditable = true,
  isCPMEditor,
  isMappedComponent,
}: {
  Inner: React.FC<BrProps>;
  displayName: string;
  isEditable?: boolean;
  isCPMEditor: boolean;
  isMappedComponent: boolean;
}) {
  return function WithWrapperInner(brProps: BrProps) {
    return (
      <EditWrapper
        displayName={displayName}
        isCPMEditor={isCPMEditor}
        isEditable={isEditable}
        isMappedComponent={isMappedComponent}
      >
        <Inner {...brProps} />
      </EditWrapper>
    );
  };
}

export default EditWrapper;
