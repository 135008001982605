import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export type Issue = {
  id: string;
  message: ReactNode;
};

export type Issues = Record<string, ReactNode>;

export type EditorIssuesContextValue = {
  isOpen: boolean;
  issues: Record<string, ReactNode>;
  addIssues: (issues: Issue[]) => void;
  clearIssue: (id: string) => void;
  toggle: () => void;
  isCPMEditor: boolean;
};

const EditorIssuesContext = createContext<EditorIssuesContextValue | undefined>(undefined);
const noOp = () => undefined;
const liveModeContextValue = {
  isOpen: false,
  issues: {},
  addIssues: noOp,
  clearIssue: noOp,
  toggle: noOp,
  isCPMEditor: false,
};

export function EditorIssuesProvider({
  children,
  isCPMEditor,
}: {
  children: ReactNode;
  isCPMEditor: boolean;
}) {
  const isLiveMode = !isCPMEditor;
  const [issues, setIssues] = useState<Issues>({});
  const [isOpen, setIsOpen] = useState(false);

  const addIssues = (_issues: Issue[]) => {
    if (!_issues.length) {
      return;
    }

    const newIssues = _issues
      .filter((i) => !issues[i.id])
      .reduce((issues: Issues, currentIssue) => {
        issues[currentIssue.id] = currentIssue.message;
        return issues;
      }, {});

    if (Object.keys(newIssues).length) {
      setIssues((prevIssues) => ({
        ...prevIssues,
        ...newIssues,
      }));
    }
  };
  const clearIssue = (id: string) => {
    if (!issues[id]) {
      return;
    }

    setIssues((issues) => {
      delete issues[id];
      return issues;
    });
  };
  const toggle = () => setIsOpen((isOpen) => !isOpen);

  const editorContextValue = {
    issues,
    isOpen,
    toggle,
    addIssues,
    clearIssue,
    isCPMEditor,
  };

  return (
    <EditorIssuesContext.Provider value={isLiveMode ? liveModeContextValue : editorContextValue}>
      {children}
    </EditorIssuesContext.Provider>
  );
}

function issueStub() {
  throw new Error('can not add or clear issues on this component');
}

export function useIssuesContext() {
  const context = useContext(EditorIssuesContext);

  if (!context) {
    return {
      addIssues: issueStub,
      clearIssue: issueStub,
      isOpen: false,
      issues: {},
      toggle: issueStub,
      isCPMEditor: false,
    };
  }

  return context;
}

export function useIssues() {
  const [issues, setIssues] = useState<Issue[]>([]);
  const { addIssues, clearIssue, isCPMEditor } = useIssuesContext();
  const isLiveMode = !isCPMEditor;

  // We have to use an effect to call addIssue because this hook gets called while rendering
  useEffect(() => {
    if (issues.length) {
      addIssues(issues);
    }
  }, [issues, addIssues]);

  const add = (issue?: Issue) => {
    if (isLiveMode || !issue) {
      return;
    }

    const exists = issues.find((i) => i.id === issue.id);

    if (exists) {
      return;
    }

    setIssues([...issues, issue]);
  };

  return {
    add,
    clear: clearIssue,
  };
}
