import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';

import {
  HalfAndHalf as HalfAndHalfOsc,
  TextBlockBody,
  TextBlockHeader,
} from '@dx-ui/osc-marketing';
import cx from 'classnames';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { useSegmentedItems } from '../../hooks/use-segments';
import { mapMultimediaListToVideoProps } from '../../utils/video';

const odd = (index: number) => index * 2 + 1;
const even = (index: number) => index * 2;

export function getForcedIndex(
  index: number,
  imageDisplay: 'left' | 'right' | 'alternate' | 'round' | 'none'
) {
  const isOddIndex = imageDisplay === 'right';
  const isEvenIndex = imageDisplay === 'left';

  return isOddIndex ? odd(index) : isEvenIndex ? even(index) : index;
}

export default createCpmComponentDefinition(
  'Half & Half',

  function mapData({ data, componentParams: { imageDisplay }, index, mappedPage }) {
    // in order to lean all of the components left or right, we can force index
    // of all items to be either odd or even
    const forcedIndex = getForcedIndex(index, imageDisplay);
    const asset = data.cpmAssets[0];
    const imageUrl = asset?.aspectRatios['3x2']?.url ?? '';
    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;
    const imageAltText = asset?.altText ?? '';
    const videoData = data?.videos?.[0];
    const video = videoData
      ? {
          source: videoData.source || '',
          title: videoData?.name || '',
          url: videoData.url,
        }
      : undefined;

    const multimedia = data?.multimedia?.length
      ? mapMultimediaListToVideoProps(data.multimedia, mappedPage)
      : undefined;

    return {
      $ref: data.ref?.$ref,
      imageUrl,
      captionData,
      imageAltText,
      index: forcedIndex,
      segmentIds: data?.segmentIds ?? [],
      headline: data?.headline ?? undefined,
      description: data?.shortDescription ?? undefined,
      media: {
        multimedia,
        video,
      },
      cmsTranslationClasses: data.cmsTranslationClasses,
    };
  },

  function HalfAndHalf({ items = [], listData: data, componentParams, mappedPage: { brandCode } }) {
    const createManageContentButton = useCreateManageContentButton();
    const filteredItems = useSegmentedItems(items, { maintainUnsegmentedCount: true });
    const isLXR = brandCode === 'OL';

    if (!filteredItems.length) {
      return null;
    }

    const itemsWithManageContentButton = filteredItems.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const isDark = componentParams?.theme === 'dark';
    const isLight = componentParams?.theme === 'light';

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        {data?.headline || data?.description ? (
          <div className="container p-6">
            {data?.headline && (
              <TextBlockHeader
                className={cx('brand-gu:text-center', {
                  'text-text-inverse': isDark,
                  'brand-ht:text-text-inverse': isLight,
                })}
              >
                {data?.headline}
              </TextBlockHeader>
            )}

            {data?.description && (
              <TextBlockBody
                className={cx('font-sans brand-gu:text-center', {
                  'text-text-inverse': isDark,
                  'brand-ht:text-text-inverse': isLight,
                })}
                brandComponentTheme={componentParams.theme}
              >
                {data?.description}
              </TextBlockBody>
            )}
          </div>
        ) : null}
        {itemsWithManageContentButton.map((item) => {
          return (
            <HalfAndHalfOsc
              key={`${item.index}-${item?.headline}`}
              id={data?.id ?? ''}
              hasParallax={isLXR}
              {...item}
            />
          );
        })}
      </BrandComponentThemeInline>
    );
  }
);
