import type { VideoControlsProps } from './video-player.controls';
import type { useVideoPlayer } from './hooks/use-video-player';
import { getCueText } from './util/get-cue-text';
import { useTranslation } from 'next-i18next';
import Icon from '@dx-ui/osc-icon';
import classnames from 'classnames';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useEventListener, useBoolean } from 'usehooks-ts';
import { getKeyDownNavigation } from '@dx-ui/utilities-accessibility';
import { BrandButton } from '@dx-ui/osc-brand-buttons';
import { isValidCue } from './util/is-valid-cue';

type PlayerTranscriptProps = ReturnType<typeof useVideoPlayer>['videoTranscriptProps'];

type VideoTranscriptProps = PlayerTranscriptProps &
  Pick<VideoControlsProps, 'brandComponentTheme'> &
  React.ComponentProps<'div'>;

type TranscriptProps = VideoTranscriptProps & {
  activeTranscript: NonNullable<VideoTranscriptProps['activeTranscript']>;
};

export function VideoTranscript(props: VideoTranscriptProps) {
  return props.activeTranscript ? (
    <Transcript
      key={props.activeTranscript.label}
      {...props}
      activeTranscript={props.activeTranscript}
    />
  ) : null;
}

function Transcript({
  activeTranscript,
  closeTranscript,
  onCueSelect,
  brandComponentTheme,
  videoElement,
  ...elementProps
}: TranscriptProps) {
  const { t } = useTranslation('osc-video-player');
  const listRef = useRef<React.ElementRef<'ol'>>(null);
  const { value: isSyncing, setTrue: startSync, setFalse: stopSync } = useBoolean(true);
  const [focusedButton, setFocusedButton] = useState<HTMLButtonElement | null>(null);
  const activeTranscriptRef = useRef(activeTranscript);
  const allCues = useMemo(() => getCues(activeTranscript), [activeTranscript]);
  const isScrolling = useRef(false);
  const getCurrentTime = () => videoElement.current?.currentTime ?? 0;
  const [activeCue, setActiveCue] = useState<VTTCue | undefined>(() =>
    getActiveCue(activeTranscript, getCurrentTime())
  );

  const maybeShowSyncButton = () => {
    if (!isScrolling.current) stopSync();
    isScrolling.current = false;
  };

  const scrollToActiveCue = useCallback(() => {
    if (listRef.current && activeCue && isSyncing) {
      const activeButton = getCurrentTimeButton(listRef);
      if (activeButton && activeButton.parentElement) {
        const { offsetTop, offsetHeight } = activeButton.parentElement;
        const { marginTop, marginBottom } = window.getComputedStyle(activeButton.parentElement);
        const itemMargins = parseInt(marginTop, 10) + parseInt(marginBottom, 10);
        const itemOffset = offsetTop - offsetHeight - itemMargins;
        const top = itemOffset - listRef.current.offsetTop;
        isScrolling.current = true;
        listRef.current.scrollTo({ top });
      }
    }
  }, [activeCue, isSyncing]);

  const onKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    switch (event.key) {
      case 'Escape':
        closeTranscript();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        {
          const onKeyDownNavigation = getKeyDownNavigation({
            elements: getAllButtons(listRef),
            onNextFocus: setFocusedButton,
            onPreviousFocus: setFocusedButton,
          });
          onKeyDownNavigation(event);
        }
        break;
      default:
        break;
    }
  };

  const onCueChange = () => {
    setActiveCue(getActiveCue(activeTranscript, getCurrentTime()));
  };

  useEffect(scrollToActiveCue, [scrollToActiveCue]);
  useEventListener('cuechange', onCueChange, activeTranscriptRef);
  useEventListener('scroll', maybeShowSyncButton, listRef);
  useEventListener('keydown', onKeyDown, listRef);

  return (
    <div {...elementProps} className={classnames('bg-bg relative p-4', elementProps.className)}>
      <div className="flex w-full items-center justify-between p-1">
        <h3 className="flex items-center pb-2 font-semibold">
          <Icon className="me-2" name="transcripts" />
          {t('transcript')}
        </h3>
        <button aria-label={t('close')} type="button" onClick={closeTranscript} autoFocus>
          <Icon name="close" />
        </button>
      </div>
      <ol
        className={classnames('max-h-64 space-y-1 overflow-y-auto overscroll-contain sm:max-h-80', {
          'pb-10': !isSyncing,
        })}
        ref={listRef}
        data-testid="video-player-transcript-list"
      >
        {allCues.map((cue) => (
          <TranscriptLine
            activeCue={activeCue}
            cue={cue}
            focusedButton={focusedButton}
            key={cue.startTime}
            onCueSelect={onCueSelect}
          />
        ))}
      </ol>
      {!isSyncing ? (
        <BrandButton
          className="absolute bottom-2 start-1/2 -translate-x-1/2 text-sm rtl:translate-x-1/2"
          onClick={() => {
            startSync();
            setFocusedButton(null);
            getCurrentTimeButton(listRef)?.focus();
          }}
          label={t('sync')}
          brandComponentTheme={brandComponentTheme}
          variant="solid"
        />
      ) : null}
    </div>
  );
}

function TranscriptLine({
  cue,
  onCueSelect,
  activeCue,
  focusedButton,
}: Pick<TranscriptProps, 'onCueSelect'> & {
  cue: VTTCue;
  activeCue?: VTTCue;
  focusedButton: HTMLButtonElement | null;
}) {
  const { t } = useTranslation('osc-video-player');
  const buttonRef = useRef(null);
  const isActive = activeCue
    ? activeCue.startTime === cue.startTime && activeCue.endTime === cue.endTime
    : false;
  const isFocused = focusedButton ? focusedButton === buttonRef.current : false;
  const tabIndex = (!focusedButton && isActive) || isFocused ? 0 : -1;

  return (
    <li className="p-1">
      <button
        type="button"
        className={classnames('hover:bg-bg-alt flex gap-4 rounded-sm p-1', {
          'bg-bg-alt': isActive,
        })}
        onClick={() => onCueSelect(cue)}
        tabIndex={tabIndex}
        ref={buttonRef}
        aria-current={isActive ? 'time' : false}
        aria-label={t('time', {
          minutes: t('minute', { count: floorMinutes(cue.startTime) }),
          seconds: t('second', { count: floorSeconds(cue.startTime) }),
          text: cue.text,
        })}
      >
        <span className="text-primary font-semibold underline">{formatTime(cue.startTime)}</span>
        <span className="text-start" dangerouslySetInnerHTML={{ __html: getCueText(cue) }} />
      </button>
    </li>
  );
}

function getActiveCue(
  activeTranscript: PlayerTranscriptProps['activeTranscript'],
  currentTime: number
) {
  const activeCues = (Array.from(activeTranscript?.track?.activeCues || []) as VTTCue[]).filter(
    isValidCue
  );
  const activeCue = activeCues[activeCues.length - 1];

  if (activeCue) {
    return activeCue;
  }

  // If we have no active cues, we still want to show the last cue that was active.
  const allCues = getCues(activeTranscript);
  const matchedCues = allCues.filter((currentCue) => currentTime > currentCue.endTime);
  return matchedCues[matchedCues.length - 1] || allCues[0];
}

function getCues(activeTranscript: PlayerTranscriptProps['activeTranscript']) {
  return (Array.from(activeTranscript?.track?.cues || []) as VTTCue[]).filter(isValidCue);
}

function formatTime(time: number) {
  const minFmt = padTime(floorMinutes(time));
  const secFmt = padTime(floorSeconds(time));
  return `${minFmt}:${secFmt}`;
}

const floorMinutes = (time: number) => Math.floor(time / 60);
const floorSeconds = (time: number) => Math.floor(time % 60);
const padTime = (time: number) => time.toString().padStart(2, '0');

function getCurrentTimeButton(
  listRef: React.RefObject<HTMLUListElement>
): HTMLButtonElement | null {
  return listRef.current?.querySelector('li > button[aria-current="time"]') || null;
}

function getAllButtons(listRef: React.RefObject<HTMLUListElement>): HTMLButtonElement[] {
  return Array.from(listRef.current?.querySelectorAll('li > button') || []);
}
