import { useCallback } from 'react';
import { BrandShowcase } from '@dx-ui/osc-marketing';
import type { TBrandShowcaseItem } from '@dx-ui/osc-marketing';
import { extractInstanceIndexFromId } from '../../utils/id-transformer';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { useBrandShowcase } from '../../Context';

export default createCpmComponentDefinition(
  'Brand Showcase',

  function mapComponentData({ data: { documentBrandCode, formalBrandName, shortDescription } }) {
    return {
      brandCode: documentBrandCode,
      label: formalBrandName,
      shortDescription,
    };
  },

  function BrandShowcaseCpm({
    items = [],
    componentParams,
    listData,
    metrics,
    mappedPage: { brandCode },
  }) {
    const brandShowcaseData = useBrandShowcase();

    const id = listData?.id ?? '';

    const onItemClicked = useCallback(
      (index: number) => {
        if (metrics) {
          const { label } = items[index];
          metrics.trackBrandShowcaseClick?.([
            {
              brandShowcaseNumber: extractInstanceIndexFromId(id),
              itemNumber: index + 1,
              totalItems: items.length,
              brandName: label ?? '',
            },
          ]);
        }
      },
      [metrics, id, items]
    );

    if (!items.length) {
      return null;
    }

    if (!brandShowcaseData) {
      return null;
    }

    const itemsWithUrl: Array<TBrandShowcaseItem> = brandShowcaseData
      ? items.map((item) => {
          const brandData = brandShowcaseData.find((brand) => brand.code === item.brandCode);
          const label = item.label ?? '';
          if (brandData) {
            return { ...item, url: brandData.url, label };
          }

          return { ...item, label };
        })
      : [];

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <BrandShowcase
          id={id}
          onItemClicked={onItemClicked}
          items={itemsWithUrl}
          /*these should be hard coded (according to dx-hotels-ui/src/components/brand-showcase/index.tsx)*/
          logoUrl="/modules/assets/svgs/logos/brand"
          baseUrl="/en"
        />
      </BrandComponentThemeInline>
    );
  }
);
