import {
  type AssetVariants,
  type StructuredAsset,
  createCpmComponentDefinition,
  useCreateManageContentButton,
  selectFirstAssetWithAspectRatio,
  selectLastAssetWithAspectRatio,
} from '@dx-ui/cpm-sdk';
import { useSegmentedItems } from '../../hooks/use-segments';
import { Collage } from '@dx-ui/osc-collage';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';

export function getIsFlipped(
  index: number,
  imageDisplay: 'left' | 'right' | 'alternate' | 'round' | 'none'
) {
  switch (imageDisplay) {
    case 'left':
      return true;

    case 'right':
      return false;

    case 'alternate':
    default:
      return Boolean(index % 2);
  }
}

export default createCpmComponentDefinition(
  'Collage',

  function mapData({ data, componentParams: { imageDisplay, animation }, index }) {
    function constructImage(asset: StructuredAsset | null, aspectRatio: keyof AssetVariants) {
      if (asset === null) {
        return null;
      }

      return {
        size: 'md',
        __typename: 'BrandImageVariant',
        _id: asset?.aspectRatios[aspectRatio]?.id ?? '',
        url: asset?.aspectRatios[aspectRatio]?.url ?? '',
        imageUrl: asset?.aspectRatios[aspectRatio]?.url ?? '',
        altText: asset?.altText ?? '',
      };
    }

    const primaryImageMobile = constructImage(
      selectLastAssetWithAspectRatio('3x2', data.cpmAssets),
      '3x2'
    );
    const primaryImageDesktop = constructImage(
      selectLastAssetWithAspectRatio('1x1', data.cpmAssets),
      '1x1'
    );
    const secondaryImage = constructImage(
      selectFirstAssetWithAspectRatio('3x2', data.cpmAssets),
      '3x2'
    );

    const primaryCaptionAsset = data.cpmAssets[1];

    const secondaryCaptionAsset = data.cpmAssets[0];

    if (!(primaryImageMobile && secondaryImage)) {
      throw new Error(
        `The "${
          data.displayName || data.name
        }" document is missing two images with the Collage image variant`
      );
    }

    if (!primaryImageDesktop) {
      throw new Error(
        `The "${
          data.displayName || data.name
        }" document is missing a Collage image variant for the mobile screensize image`
      );
    }

    if (!primaryImageMobile) {
      throw new Error(
        `The "${
          data.displayName || data.name
        }" document is missing a ThreeSixNineGrid image variant for the mobile screensize image`
      );
    }

    const { ref: _ref } = (data = { ...data });

    const isFlipped = getIsFlipped(index, imageDisplay);

    return {
      id: data.id,
      shortDescription: data.shortDescription,
      headline: data.headline,
      $ref: _ref?.$ref,
      primaryImageMobile,
      primaryImageDesktop,
      secondaryImage,
      longDescription: data.longDescription ?? '',
      logo: undefined,
      isFlipped,
      cta: data.link?.url && data.link?.label ? { ...data.link, text: data.link.label } : undefined,
      ...(data.logoUrl
        ? {
            logo: {
              altText: '',
              imageUrl: data.logoUrl ?? '',
            },
          }
        : null),

      primaryCaptionData: primaryCaptionAsset
        ? {
            captionLink: primaryCaptionAsset?.captionLink,
            caption: primaryCaptionAsset?.caption,
          }
        : {},
      secondaryCaptionData: secondaryCaptionAsset
        ? {
            captionLink: secondaryCaptionAsset?.captionLink,
            caption: secondaryCaptionAsset?.caption,
          }
        : {},
      cmsTranslationClasses: data.cmsTranslationClasses,
      isAnimated: animation,
      segmentIds: data.segmentIds,
    };
  },

  function CollageCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items);
    const createManageContentButton = useCreateManageContentButton();

    if (filteredItems.length === 0) {
      return null;
    }

    const itemsWithManageContentButton = filteredItems.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    return (
      <BrandComponentThemeInline
        componentClassName="collage"
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        {itemsWithManageContentButton.map((item) => {
          return (
            <Collage
              key={item.id}
              {...item}
              isAnimated={componentParams.animation}
              brandComponentTheme={componentParams.theme}
            />
          );
        })}
      </BrandComponentThemeInline>
    );
  }
);
