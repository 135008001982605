import { useIssuesContext } from '../hooks/use-issues';

export function EditorIssues({ id }: { id: string }) {
  const { issues, isOpen, toggle } = useIssuesContext();
  const panelId = `${id}-issues`;
  const issueCount = Object.keys(issues).length;
  const issuePostfix = `issue${issueCount > 1 ? 's' : ''}`;

  if (!issueCount) {
    return null;
  }

  return (
    <div className="min-h-14 overflow-visible text-left">
      <div className="absolute left-2 top-2 z-50 ml-2 rounded-lg">
        <button
          aria-expanded={isOpen}
          aria-controls={panelId}
          className="editor-hints-cta bg-warn w-40"
          onClick={() => toggle()}
          type="button"
        >
          &#9888; {isOpen ? 'Hide' : 'Show'} {issueCount} {issuePostfix}
        </button>
        <div className="bg-warn-alt mt-2 rounded-lg px-4 py-2" id={panelId} hidden={!isOpen}>
          <ul className="list-disc pl-2">
            {Object.keys(issues).map((key) => {
              return (
                <li key={key} className="leading-6">
                  {issues[key]}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
