import type { Document } from '@bloomreach/spa-sdk';
import type { AnalyticsTagging, BrPage } from '../adapters/types';
import {
  getArticleTitle,
  getPagePrimaryImage,
  includesBrandShowcase,
} from '../fetching/get-page-seo-data';
import { getBrandCodeFromPage } from './getBrandCodeFromPage';

function getPageDocumentData(pageBr: BrPage) {
  return pageBr.getDocument<{
    model: {
      data: {
        contentAssociation: string[];
        contentType: 'dxcms:Page';
        hidePageHeader: boolean | null;
        hidePageFooter: boolean | null;
        seo: {
          hideSearchEngine: boolean;
          metaDescription: string | null;
          metaKeywords: string | null;
          pageHeading: string | null;
          pageTitle: string | null;
        };
        /**
         * Allow the CMS to override the inferred default tagging values (based off of the pageType).
         * Note: The `analyticsTagging` object was introduced in BRCMS-2748 so older pages may not have this field.
         */
        analyticsTagging?: AnalyticsTagging;
        type: string;
      };
    };
    type: 'document';
  }>();
}

/**
 * This is usage-specific data that can not be provideded in a generalized way by CPM.
 *
 * It includes:
 * - Data that is outside of the scope of CPM, eg:
 *   - cookie information
 *   - current browser pathname
 *   - locale / language / region
 * - Data that must be calculated from the CPM page and provided to the app, eg:
 *   - seo information
 *   - page title
 *   - page header image
 *   - analytics tagging
 *
 * Must be calculated by a function that can be passed to cpm's fetchServerCpmPage function.
 * Its output will be included in the CpmDataBundle, and passed to every mapping function
 * under the key `domainData`
 */
export type CpmMappedPage = {
  brandCode: string;
  articleTitle: string;
  doesIncludeBrandShowcase: boolean;
  seoImage: string;

  hidePageHeader: boolean;
  hidePageFooter: boolean;
  pageType: string;
  pathname: string;
  localeCode: string;
  languageCode: string;
  regionCode?: string;
  seo: {
    hideSearchEngine: boolean;
    metaDescription: string | null;
    metaKeywords: string | null;
    pageHeading: string | null;
    pageTitle: string | null;
  };
  analyticsTagging: AnalyticsTagging;
};

export function extractMappedPage(
  pathname: string,
  localeCode: string,
  pageBr: BrPage
): CpmMappedPage {
  const { language: languageCode, region: regionCode } = new Intl.Locale(localeCode);

  const pageModel = pageBr.toJSON();

  const documentData = getPageDocumentData(pageBr);

  const seo = documentData?.model?.data?.seo ?? ({} as Record<string, undefined>);
  const analyticsTagging =
    documentData?.model?.data.analyticsTagging ?? ({} as Record<string, undefined>);

  const brandCode = getBrandCodeFromPage(pageModel);

  return {
    brandCode,
    articleTitle: getArticleTitle(pageModel) ?? '',
    seoImage: getPagePrimaryImage(pageBr) ?? '',
    doesIncludeBrandShowcase: !!includesBrandShowcase(pageModel),

    hidePageHeader: !!documentData?.model.data.hidePageHeader,
    hidePageFooter: !!documentData?.model.data.hidePageFooter,
    localeCode,
    languageCode,
    regionCode,
    pageType: pageBr.getDocument<Document>()?.getData()?.type,
    pathname,
    seo: {
      hideSearchEngine: !!seo.hideSearchEngine,
      metaDescription: seo.metaDescription ?? null,
      metaKeywords: seo.metaKeywords ?? null,
      pageHeading: seo.pageHeading ?? null,
      pageTitle: seo.pageTitle ?? null,
    },
    analyticsTagging: {
      pageDetailOne: analyticsTagging.pageDetailOne ?? '',
      pageDetailTwo: analyticsTagging.pageDetailTwo ?? '',
      pageDetailThree: analyticsTagging.pageDetailThree ?? '',
      primaryCategory: analyticsTagging.primaryCategory ?? null,
      adobePageType: analyticsTagging.adobePageType ?? null,
      trackEventType: analyticsTagging.trackEventType ?? null,
    },
  };
}
