import { useRef } from 'react';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { useMediaQuery, useResizeObserver } from 'usehooks-ts';
import cx from 'classnames';
import { YouTubeVideo } from '@dx-ui/osc-youtube-video';
import {
  Video,
  VideoCaptions,
  VideoControls,
  VideoTranscript,
  useVideoPlayerClasses,
  useVideoPlayer,
  VideoPlayerMarkup,
} from '@dx-ui/osc-video-player';
import { TextBlockBody } from '@dx-ui/osc-marketing';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { HorizontalTabPanelProps } from './HorizontalTabs.types';
import { useRect } from '@dx-ui/utilities-use-rect';

export const HorizontalTabbedSectionContent = ({
  brandComponentTheme,
  copy,
  longDescription,
  headline,
  image,
  link,
  media,
}: HorizontalTabPanelProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isYouTubeShort = media?.video?.source === 'youtube-short';
  const isPortrait = media?.multimedia?.orientation === 'portrait';
  const isLandscape = media?.multimedia?.orientation === 'landscape';
  const isMultimediaVideo = Boolean(media?.multimedia?.videoUrl);
  const isVideo = Boolean(media?.video?.url || isMultimediaVideo);
  const isVerticalVideo = isVideo && (isYouTubeShort || isPortrait);
  const isImage = Boolean(image?.alt && image?.url);
  const captionData = image?.captionData;
  const videoUrl = media?.multimedia?.videoUrl || '';
  const playerProps = useVideoPlayer({
    ...media?.multimedia,
    videoUrl,
    wrapperElement: ref,
  });
  const playerClassNames = useVideoPlayerClasses({ isLandscape });
  const hasActiveTranscript = Boolean(playerProps.videoTranscriptProps?.activeTranscript);
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const videoSize = useResizeObserver({ ref: playerProps.videoProps.videoElement });
  const height = videoSize?.height ?? 0;
  const transcriptStyle = isLarge && !isVerticalVideo ? { height: `${height}px` } : undefined;
  const hasMediaContent = isImage || isVideo;

  const Transcript = (
    <VideoTranscript
      {...playerProps.videoTranscriptProps}
      style={transcriptStyle}
      brandComponentTheme={brandComponentTheme}
      className={cx('overflow-hidden !p-0 lg:mb-6', {
        '[&>ol]:lg:max-h-full [&>ol]:lg:pb-20': !isVerticalVideo,
        '[&>ol]:lg:max-h-64': isVerticalVideo,
      })}
    />
  );

  return (
    <div
      data-testid="tabbed-section-content-wrapper"
      ref={ref}
      className={cx('relative flex lg:mx-8 lg:mt-8 brand-wa:lg:px-0', {
        'flex-row-reverse': hasMediaContent,
        'justify-center': isVerticalVideo,
        'justify-between': !isVerticalVideo,
        'items-start': hasActiveTranscript,
      })}
    >
      {media?.multimedia?.markupSchemas ? (
        <VideoPlayerMarkup markupSchemas={media.multimedia.markupSchemas} />
      ) : null}
      <div
        className={cx('flex flex-col justify-center w-full', {
          'max-w-sm': isVerticalVideo,
          'lg:w-1/2': !isVerticalVideo && hasMediaContent,
        })}
      >
        <div
          className={cx('lg:ps-7', {
            'lg:pt-0 pt-10': !hasActiveTranscript,
          })}
        >
          <div className="hidden lg:block">{Transcript}</div>
          {headline ? (
            <h3
              className={cx('heading-2xl mb-4 lg:mb-6 lg:heading-4xl', {
                '!text-text-inverse brand-ht:!text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
              data-testid="textBlockHeader"
            >
              {headline}
            </h3>
          ) : null}
          {copy ? (
            <TextBlockBody
              brandComponentTheme={brandComponentTheme}
              className={cx('mb-4 lg:mb-6', {
                'text-text-inverse brand-ht:text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {copy}
            </TextBlockBody>
          ) : null}
        </div>

        <div
          className={cx('start-0 lg:absolute pe-7', {
            'top-0': hasActiveTranscript,
            'lg:w-4/12 lg:start-[calc(100%/3/2)]': isVerticalVideo,
            'lg:w-1/2': !isVerticalVideo && !isImage,
            'lg:min-w-[55%]': isImage,
          })}
        >
          {media?.video?.url && media?.video.title ? (
            <YouTubeVideo
              {...media.video}
              wrapperClassName="flex-1"
              isAutoPlay={false}
              isAutoLoop={false}
            />
          ) : videoUrl ? (
            <>
              <div className={cx(playerClassNames.wrapperClassNames, 'mb-8 lg:mb-0')}>
                <Video
                  {...playerProps.videoProps}
                  captionData={media?.multimedia?.captionData}
                  isAutoPlay={media?.multimedia?.isAutoPlay}
                  posterImageUrl={media?.multimedia?.posterImageUrl}
                  videoId={media?.multimedia?.videoId}
                  videoUrl={videoUrl}
                />
                <VideoCaptions
                  {...playerProps.videoCaptionProps}
                  brandComponentTheme={brandComponentTheme}
                  className={playerClassNames.captionClassNames}
                />
                <VideoControls
                  {...playerProps.videoControlsProps}
                  brandComponentTheme={brandComponentTheme}
                  className={playerClassNames.controlsClassNames}
                />
              </div>
              <div className="lg:hidden">{Transcript}</div>
            </>
          ) : null}

          {image?.alt && image?.url ? (
            <ResponsiveImage
              altText={image.alt}
              imageUrl={image.url}
              wrapperClassName="flex-[1.3]"
              aspectRatio="3:2"
              width={rect?.width ?? 0}
              captionData={captionData}
            />
          ) : null}
        </div>
        <div className={cx('mt-4 lg:mt-0 md:mb-2 lg:ps-7')}>
          {longDescription ? (
            <TextBlockBody
              className={cx('font-sans mb-4 lg:mb-6', {
                'text-text-inverse brand-ht:text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
                'pt-8 sm:pt-4': isMultimediaVideo && !hasActiveTranscript,
              })}
              brandComponentTheme={brandComponentTheme}
            >
              {longDescription}
            </TextBlockBody>
          ) : null}

          {link.label && link.url ? (
            <BrandLink
              url={link.url}
              label={link.label}
              onClick={link.onClick}
              isNewWindow={link.isNewWindow}
              showNewWindowIcon={link.isNewWindow}
              brandComponentTheme={brandComponentTheme}
            />
          ) : null}
        </div>
      </div>
      {hasMediaContent ? (
        <div
          className={cx('hidden lg:block', {
            'aspect-[9/16] lg:w-4/12': isVerticalVideo,
            'aspect-[3/2] lg:w-1/2': !isVerticalVideo && !isImage,
            'aspect-[3/2] lg:min-w-[55%]': isImage,
          })}
        />
      ) : null}
    </div>
  );
};
