import dynamic from 'next/dynamic';
import type { AppProps } from 'next/app';
import { ErrorBoundary } from '@dx-ui/osc-error-boundary';
import '../styles/global.css';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { useRouter } from 'next/router';
import type { FallbackProps } from 'react-error-boundary';
import { getQueryProviderProps } from '@dx-ui/cpm-mapping';
import { useManualPageDynatrace } from '@dx-ui/framework-dynatrace';

const APP_NAME = 'dx-brands-ui';

const ErrorFallback = dynamic(
  () => import('../components/ErrorFallback').then((lib) => lib.ErrorFallback),
  {
    ssr: false,
  }
);

function App({ Component, pageProps, router }: AppProps) {
  const safeQueryProviderEnv: Partial<Record<string, string | undefined>> = pageProps.env;
  const queryProps = getQueryProviderProps(safeQueryProviderEnv);
  const routerLocale = useRouter().locale || router.locale || 'en';
  useManualPageDynatrace(router.pathname, APP_NAME);

  const ErrorEnvWrapper = (props: FallbackProps) => (
    <ErrorFallback {...props} env={pageProps.env} />
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorEnvWrapper}>
      <QueryProvider
        {...queryProps}
        dehydratedQueryState={pageProps.dehydratedQueryState}
        appName="dx-cpm-live"
        oneLinkConfig={pageProps.oneLinkConfig}
        routerLocale={routerLocale}
      >
        <Component {...pageProps} />
      </QueryProvider>
    </ErrorBoundary>
  );
}

export default appWithTranslation(App);
