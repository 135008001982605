import { useRef, useState, useEffect, useCallback } from 'react';
import { useEventListener } from 'usehooks-ts';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { useTrackQuery } from '../hooks/use-track-query';
import { useFadeControls } from './use-fade-controls';
import { findCaption, findTranscript } from '../util/matchers';
import { getMetricsTrackName, getMetricsVideoName, trackEvent } from '../util/metrics';

import type { VideoTrack, TrackOption, BaseVideoTrack } from '../video-player.controls';

export type VideoPlayerProps = BaseVideoTrack &
  Partial<{
    isAutoPlay: boolean;
    audioTracks: BaseVideoTrack[];
    wrapperElement: React.RefObject<HTMLDivElement>;
  }>;

export type PlayerState = {
  isMuted: boolean;
  isPlaying: boolean;
  showCaptions: boolean;
  activeCaptionTrack: TrackOption | null;
  activeTranscript: TrackOption | null;
  activeVideoTrack: VideoTrack | null;
};

const defaultPlayerState: PlayerState = {
  isMuted: true,
  isPlaying: true,
  showCaptions: false,
  activeCaptionTrack: null,
  activeTranscript: null,
  activeVideoTrack: null,
};

/**
 * Hook to manage the state and behavior of the video player.
 */
export function useVideoPlayer(props: VideoPlayerProps) {
  const videoElement = useRef<HTMLVideoElement>(null);
  const hookProps = { ...props, videoElement };
  const [playerState, setPlayerState] = useState<PlayerState>(defaultPlayerState);
  const optionsState = useTrackQuery(hookProps);
  const controlsState = useFadeControls({ ...hookProps, ...playerState });
  const activeVideo = playerState.activeVideoTrack || optionsState[0];
  const videoName = getMetricsVideoName(props.videoName ?? '');

  const playVideo = useCallback(() => videoElement.current?.play()?.catch(() => null), []);

  const pauseVideo = useCallback(() => videoElement.current?.pause(), []);

  const muteVideo = useCallback(
    () => videoElement.current && (videoElement.current.muted = true),
    []
  );

  const unmuteVideo = useCallback(
    () => videoElement.current && (videoElement.current.muted = false),
    []
  );

  function togglePlayButton() {
    setPlayerState((playerState) => {
      const isPlaying = !playerState.isPlaying;
      if (isPlaying) {
        playVideo();
        trackEvent('user_play', videoName);
      } else {
        pauseVideo();
      }
      return { ...playerState, isPlaying };
    });
  }

  function toggleMuteButton() {
    setPlayerState((playerState) => {
      const isMuted = !playerState.isMuted;
      trackEvent('sound', videoName);
      isMuted ? muteVideo() : unmuteVideo();
      return { ...playerState, isMuted };
    });
  }

  function toggleCaptions() {
    setPlayerState((playerState) => {
      trackEvent('cc', videoName);
      const showCaptions = !playerState.showCaptions;
      return { ...playerState, showCaptions };
    });
  }

  function updateCaption(selectedCaption: PlayerState['activeCaptionTrack']) {
    setPlayerState((playerState) => {
      const isSingleCaption = (activeVideo?.captionTracks?.length ?? 1) === 1;
      const trackName = getMetricsTrackName(selectedCaption);
      const eventName = isSingleCaption ? 'cc' : (`sub_${trackName}` as const);
      trackEvent(eventName, videoName);
      return {
        ...playerState,
        showCaptions: Boolean(selectedCaption),
        activeCaptionTrack: selectedCaption || playerState.activeCaptionTrack,
      };
    });
  }

  function updateTranscript(selectedTranscript: PlayerState['activeTranscript']) {
    setPlayerState((playerState) => {
      const trackName = getMetricsTrackName(selectedTranscript);
      const eventName = `transcript_${trackName}` as const;
      trackEvent(eventName, videoName);
      return { ...playerState, activeTranscript: selectedTranscript };
    });
  }

  function updateAudioTrack(selectedVideoTrack: NonNullable<PlayerState['activeVideoTrack']>) {
    setPlayerState((playerState) => {
      if (playerState.activeVideoTrack?.videoUrl === selectedVideoTrack.videoUrl) {
        return playerState;
      }

      const activeCaptionTrack = playerState.activeCaptionTrack
        ? findCaption({ selectedVideoTrack, activeTrack: playerState.activeCaptionTrack })
        : null;

      const activeTranscript = playerState.activeTranscript
        ? findTranscript({ selectedVideoTrack, activeTrack: playerState.activeTranscript })
        : null;

      if (videoElement.current) {
        const isGroupMatch = activeVideo?.videoGroup === selectedVideoTrack.videoGroup;
        const currentTime = isGroupMatch ? videoElement.current.currentTime : 0;
        videoElement.current.src = selectedVideoTrack.videoUrl;
        videoElement.current.currentTime = currentTime;
      }

      const videoLabel = getMetricsVideoName(selectedVideoTrack.videoLabel || '');
      const eventName = `audiotrack_${videoLabel}` as const;
      trackEvent(eventName, videoName);
      playerState.isPlaying ? playVideo() : pauseVideo();

      return {
        ...playerState,
        activeTranscript,
        activeCaptionTrack,
        activeVideoTrack: selectedVideoTrack,
      };
    });
  }

  function closeTranscript() {
    updateTranscript(null);
  }

  function onCueSelect(cue: VTTCue) {
    videoElement.current && (videoElement.current.currentTime = cue.startTime);
  }

  const shouldAutoPlay = useCallback(() => {
    const isReducedMotion = getIsReducedMotion();
    return (props?.isAutoPlay ?? defaultPlayerState.isPlaying) && !isReducedMotion;
  }, [props?.isAutoPlay]);

  useEffect(() => {
    const isAutoPlay = shouldAutoPlay();

    setPlayerState((playerState) => {
      if (isAutoPlay) {
        muteVideo();
        playVideo();
      } else {
        unmuteVideo();
        pauseVideo();
      }
      return {
        ...playerState,
        isMuted: isAutoPlay,
        isPlaying: isAutoPlay,
      };
    });
  }, [muteVideo, pauseVideo, playVideo, shouldAutoPlay, unmuteVideo]);

  useEffect(() => {
    if (playerState.showCaptions) {
      const inActiveCaptionTracks = (activeVideo?.captionTracks ?? []).filter(
        (option): option is HTMLTrackElement => option.src !== playerState.activeCaptionTrack?.src
      );

      if (inActiveCaptionTracks.length) {
        inActiveCaptionTracks.forEach((track) => {
          track.track.oncuechange = null;
        });
      }

      if (playerState.activeCaptionTrack) {
        // eslint-disable-next-line react-compiler/react-compiler
        playerState.activeCaptionTrack.track.oncuechange = () => {
          setPlayerState((playerState) => ({
            ...playerState,
            activeCaptionTrack: playerState.activeCaptionTrack,
          }));
        };
      }
    } else {
      if (playerState.activeCaptionTrack) {
        playerState.activeCaptionTrack.track.oncuechange = null;
      }
    }
  }, [activeVideo?.captionTracks, playerState.activeCaptionTrack, playerState.showCaptions]);

  useEventListener('metrics-ready', () => {
    if (shouldAutoPlay() && videoName) {
      trackEvent('autoplay', videoName);
    }
  });

  return {
    videoProps: {
      videoElement,
    },
    videoCaptionProps: {
      showCaptions: playerState.showCaptions,
      activeCaptionTrack: playerState.activeCaptionTrack,
    },
    videoTranscriptProps: {
      activeTranscript: playerState.activeTranscript,
      closeTranscript,
      onCueSelect,
      videoElement,
    },
    videoControlsProps: {
      controlsState,
      activeVideo,
      playerState,
      optionsState,
      togglePlayButton,
      toggleMuteButton,
      toggleCaptions,
      updateCaption,
      updateTranscript,
      updateAudioTrack,
    },
  };
}
