export const envVarsToExposeToBrowser = [
  'APP_ENV',
  'APP_NAME',
  'APP_VER',
  'ASSETS_URI',
  'BROWSER_DX_GQL_URI',
  'CMS_BASE_URL',
  'CONDUCTRICS_URI',
  'DX_AUTH_API_CLIENT_URI',
  'DX_AUTH_API_CUSTOMER_APP_ID',
  'DX_AUTH_APP_CUSTOMER_ID',
  'DX_AUTH_UI',
  'DX_AUTOCOMPLETE_URI',
  'DX_GRAPHQL_CLIENT_URI',
  'ENABLE_BRANDS_SLUG',
  'GLOBAL_PRIVACY_URL',
  'HILTON_ASSETS_URI',
  'HONORS_JOIN_URL',
  'IS_LIVE_MODE',
  'LOCATION_PROVIDER_API',
  'OHW_BASE_URL',
  'PARTNER_FORGET_INFO_LINK',
  'SPA_BASE_URL',
  'TRACKING_SEED_FILE',
  'RUMCS_CAPTURE_CUSTOM_ERRORS',
  'cmsBaseUrl',
  'spaBaseUrl',
] as const;

export type EnvironmentVariables = Partial<
  Record<(typeof envVarsToExposeToBrowser)[number], string | undefined>
>;

export const isBrowser: boolean = typeof window !== 'undefined';

type AppEnv = 'prd' | 'stg' | 'test' | 'dev';

export const getAppEnv = (env: EnvironmentVariables): AppEnv => {
  switch (env.APP_ENV) {
    case 'prd':
      return 'prd';
    case 'stg':
      return 'stg';
    case 'test':
      return 'test';
    default:
      return 'dev';
  }
};

export const isProdApp = (env: EnvironmentVariables) => getAppEnv(env) === 'prd';

// cookie name for the real Adobe Audience Manager cookie
export const TMS_LIVE_COOKIE_NAME = 'TMS';
// in lower environments a dev cookie can be used since Adobe launch script overwrites any changes on page load, which makes testing challenging
export const TMS_DEV_COOKIE_NAME = 'TMS_DEV';

export const namespaces = [
  'common',
  'cpm-mapping-shared',
  'osc-accordion',
  'osc-alert',
  'osc-back-to-top',
  'osc-calendar',
  'osc-caption',
  'osc-card-carousel',
  'osc-carousel',
  'osc-chip',
  'osc-date-picker',
  'osc-dialog',
  'osc-dynamic-grids',
  'osc-footer',
  'osc-form',
  'osc-header',
  'osc-hero-image',
  'osc-hero-text-overlay',
  'osc-info-popup',
  'osc-language-selector',
  'osc-link',
  'osc-location',
  'osc-marketing',
  'osc-marketing-brand-showcase',
  'osc-marketing-dx-dialog-with-content',
  'osc-marketing-dx-image-carousel',
  'osc-marketing-dynamic-grid',
  'osc-marketing-error',
  'osc-marketing-half-and-half',
  'osc-pagination',
  'osc-rooms',
  'osc-scroll-animation',
  'osc-scrollable-tabs',
  'osc-shop-form',
  'osc-smb-profile-switcher',
  'osc-special-rates',
  'osc-speedbump',
  'osc-stepper',
  'osc-tooltip',
  'osc-video-player',
  'special-rates',
];
