import type { ReactNode } from 'react';
import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import Head from 'next/head';
import { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomHead } from './CustomHead';
import { getAssetPath, getBrandSvg } from '../utils/assets';
import { BackToTop } from '@dx-ui/osc-back-to-top';
import type { CpmMappedPage } from '@dx-ui/cpm-sdk';
import type { TBrandsQuery } from '../types';

const Layout = ({
  children,
  top,
  bottom,
  brandCode,
  localeCode,
  baseAppUrl,
  fullBrandPath,
  brandData,
  mappedPage: { seo, articleTitle, seoImage },
  env,
  includeBackToTopButton,
  supportedLanguages,
}: {
  children: ReactNode;
  top?: ReactNode;
  bottom?: ReactNode;
  brandCode: string;
  localeCode: string;
  baseAppUrl: string;
  fullBrandPath: string;
  mappedPage: CpmMappedPage;
  brandData?: TBrandsQuery;
  env: EnvironmentVariables;
  includeBackToTopButton: boolean;
  supportedLanguages?: string[];
}) => {
  const mainRef = useRef<HTMLElement | null>(null);

  // List of all brand code that have darkmode icons
  const brandsWithDarkModeFavicon = ['DT', 'OL', 'PE', 'PO', 'QQ', 'SA', 'UA', 'WW', 'WA', 'UP'];
  const favIconSuffix = brandsWithDarkModeFavicon.includes(brandCode) ? '-dark' : '';
  const favIconPath = `${getAssetPath(env)}/img/brand/${brandCode}/favicon_${brandCode}`;

  return (
    <>
      <CustomHead
        pageTitle={seo?.pageTitle ?? ''}
        metaDescription={seo?.metaDescription ?? undefined}
        metaKeywords={seo?.metaKeywords ?? undefined}
        brandLogoImageSrc={getBrandSvg(env, brandCode)}
        hideSearchEngine={seo?.hideSearchEngine ?? undefined}
        schemaName={brandData?.formalName ?? undefined}
        schemaUrl={brandData?.url ?? undefined}
        baseAppUrl={baseAppUrl}
        localeCode={localeCode}
        seoImage={seoImage}
        seoArticleTitle={fullBrandPath.includes('/articles/') ? articleTitle : undefined}
        brandCode={brandData?.code}
        socialMediaLinks={brandData?.socialMediaLinks}
        phoneNumber={brandData?.phone?.number}
        fullBrandPath={fullBrandPath}
        env={env}
        supportedLanguages={supportedLanguages}
      />
      <Head>
        <link rel="icon" href={`${favIconPath}.ico`} media="(prefers-color-scheme: light)" />
        <link
          rel="icon"
          href={`${favIconPath}${favIconSuffix}.ico`}
          media="(prefers-color-scheme: dark)"
        />
      </Head>

      <div className="isolate m-0 w-full p-0">
        <div className="z-2">{top}</div>
        <div className="z-1 relative flex flex-col">
          <main ref={mainRef}>
            <div className="outer-wrapper">{children}</div>
            {includeBackToTopButton ? <BackToTop mainRef={mainRef} /> : null}
          </main>
          {bottom}
        </div>
      </div>
    </>
  );
};

const LayoutWithTranslation = withTranslation()(Layout);

export default LayoutWithTranslation;
