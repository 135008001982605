import type { QueryClient } from '@tanstack/react-query';
import type { CpmClientBundle } from '@dx-ui/cpm-sdk';
import type { OneLinkConfig, SupportedLanguage } from '@dx-ui/framework-i18n';
import { shouldIncludeLanguageParam } from '@dx-ui/framework-i18n';
import { GraphError } from '@dx-ui/framework-react-query';
import { RuleEngine } from '@dx-shared/rule-engine';

import type { CpmServerData } from '../generatedCpmInterface';
import { fetchServerCpmPage as fetchServerPage } from '../generatedCpmInterface';
import { serverSideGetTranslatedCmsUrlsQuery } from '../generated/queries';
import { getBrandTaggingValues } from '../utils/get-brand-tagging-values';

export type CpmServerBundleWithLanguages = CpmServerData & {
  supportedLanguages: SupportedLanguage[];
};

export type CpmData = CpmServerBundleWithLanguages | CpmClientBundle;

const sectionNames = ['top', 'main', 'footer'];

const DEFAULT_LANGUAGE = 'en';

/**
 * Fetch page data from Core+ and determine what languages the page supports.
 *
 *
 * The OneLink config and a config-rule (rule-ui-translate-cms) is used to
 * determine what languages the content path supports.
 */
export async function fetchServerCpmPageWithSupportedLanguages({
  contentPath,
  localeCode,
  pathname: _pathname,
  queryClient,
  oneLinkConfig,
}: {
  contentPath: string;
  localeCode: string;
  pathname: string;
  queryClient: QueryClient;
  oneLinkConfig: OneLinkConfig | null;
}): Promise<CpmServerBundleWithLanguages> {
  // All the languages supported across all apps
  const oneLinkSupportedLanguages = oneLinkConfig?.supportedLanguages || [];

  // Config rule data
  const translatedCmsUrlsConfigQuery = await serverSideGetTranslatedCmsUrlsQuery(queryClient);
  const cmsRuleUrlConditions = translatedCmsUrlsConfigQuery?.featureConfigs[0]?.conditions;
  const routesSupportedInAllLanguages =
    translatedCmsUrlsConfigQuery?.featureConfigs[0]?.config?.routesSupportedInAllLanguages;
  // These paths are available in more languages than what are configured via the OneLink config for CPM
  const contentPathsAvailableInAdditionalLanguages: string[] = Array.isArray(
    routesSupportedInAllLanguages
  )
    ? routesSupportedInAllLanguages
    : [];

  // Config rules use the browser pathname over content path, so we need the pathname without locale OR search params
  const pathname = new URL(_pathname, 'https://www.hilton.com').pathname;

  const cmsRuleEngine = cmsRuleUrlConditions
    ? new RuleEngine({
        name: 'rule-ui-translate-cms',
        conditions: cmsRuleUrlConditions,
      })
    : null;

  // TODO: CPM-1702 - remove toggle once we have verified code is working in stage/prod
  const inLanguageToggleEnabled = translatedCmsUrlsConfigQuery?.featureToggles[0].enabled;

  /**
   * Attempt to return the English version of the page *IF* the
   * in-language/translated page 404's.
   *
   * Note: The English version of the page could 404 if it doesn't exist
   * in the CMS.
   */
  const canFallbackToEnPage =
    inLanguageToggleEnabled && Boolean(cmsRuleEngine?.run({ url: pathname, locale: localeCode }));

  const localeContentPath = getContentPath({
    contentPath,
    localeCode,
    includePDir: canFallbackToEnPage,
  });
  const englishFallbackContentPath = getContentPath({
    contentPath,
    localeCode,
    removeLocale: true,
  });

  // This prevents us from making two requests when the content path is the same and the locale equals 'en'
  const isDuplicateEnContentPath =
    localeContentPath === englishFallbackContentPath && localeCode === DEFAULT_LANGUAGE;

  const localePage = await fetchServerPage(
    queryClient,
    localeContentPath,
    localeCode,
    _pathname,
    sectionNames,
    getBrandTaggingValues
  ).catch(handleServerFetchError);

  const englishFallbackPage =
    !isDuplicateEnContentPath && canFallbackToEnPage
      ? await fetchServerPage(
          queryClient,
          englishFallbackContentPath,
          DEFAULT_LANGUAGE,
          _pathname,
          sectionNames,
          getBrandTaggingValues
        ).catch(handleServerFetchError)
      : null;

  // This content path only exists for this specific `localeCode` and doesn't have an 'en' version
  const isPageInLanguageOnly =
    !isDuplicateEnContentPath && canFallbackToEnPage && englishFallbackPage === null;

  const page = localePage || englishFallbackPage;

  if (page === null) {
    // Include all the variables used to determine which content paths are requested for debugging
    throw new FetchServerCpmPage404Error(`No data for contentPath`, {
      contentPath,
      localeCode,
      inLanguageToggleEnabled,
      canFallbackToEnPage,
      localeContentPath,
      englishFallbackContentPath,
      isDuplicateEnContentPath,
      isPageInLanguageOnly,
      localePageExists: !!localePage,
      englishFallbackPageExist: !!englishFallbackPage,
    });
  }

  /**
   * Work out what additional languages this content path is available in.
   *
   * English language paths will always get checked against the rule.
   *
   * Pages that don't have a fallback OR that exist for a specific language
   * won't have any additional languages.
   */
  const canHaveAdditionalLanguages = canFallbackToEnPage && !isPageInLanguageOnly;

  const supportedLanguages = canHaveAdditionalLanguages
    ? oneLinkSupportedLanguages.filter((language) => {
        // Is translation enabled for the content path in this specific language?
        const isSupportedCMSRoute = cmsRuleEngine?.run({
          url: page.contentPath,
          locale: language,
        });
        // Is the content path + language enabled for translation via OneLink proxy?
        const isSupportedByOneLinkConfig = shouldIncludeLanguageParam(
          language,
          'dx-cpm-live',
          pathname,
          oneLinkConfig
        );
        const isLanguageAvailable = isSupportedCMSRoute && isSupportedByOneLinkConfig;

        /**
         * Is this content path supported in more languages than then OneLink toggle has configured for CPM?
         *
         * Note: we use the `englishFallbackContentPath` here because the path doesn't contain a locale, which is what
         * the rules in `contentPathsAvailableInAdditionalLanguages` expect.
         */
        const isAvailableInAdditionalLanguages =
          contentPathsAvailableInAdditionalLanguages.includes(englishFallbackContentPath);

        return isAvailableInAdditionalLanguages || isLanguageAvailable;
      })
    : [];

  if (supportedLanguages.length > 0) {
    supportedLanguages.unshift('en');
  }

  return {
    ...page,
    // Languages the page supports in addition to the requested one
    supportedLanguages,
  };
}

function getContentPath({
  contentPath,
  localeCode,
  includePDir,
  removeLocale,
}: {
  contentPath: string;
  localeCode: string;
  includePDir?: boolean;
  removeLocale?: boolean;
}) {
  if (includePDir && !contentPath.includes('/p/')) {
    contentPath = contentPath.replace(`/${localeCode}/`, `/${localeCode}/p/`);
  }

  if (removeLocale) {
    contentPath = contentPath.replace(`/${localeCode}/`, '/');
  }

  return contentPath;
}

function handleServerFetchError(error: unknown) {
  if (is404Error(error)) {
    return null;
  }
  throw error;
}

function is404Error(error: unknown) {
  return (
    error instanceof GraphError &&
    Array.isArray(error.graphQLErrors) &&
    error.graphQLErrors[0]?.message === 'Not Found'
  );
}

type FetchServerCpmPageErrorVariables = Record<string, string | boolean>;

export class FetchServerCpmPage404Error extends Error {
  public variables: FetchServerCpmPageErrorVariables = {};

  constructor(
    message: string,
    variables: FetchServerCpmPageErrorVariables,
    options?: ErrorOptions
  ) {
    super(message);
    this.name = 'CpmFetchServerPageError';
    this.variables = variables;
    this.cause = options?.cause;
  }
}
