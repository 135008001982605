/* eslint-disable react/jsx-no-useless-fragment */
import { useRef } from 'react';
import cx from 'classnames';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { TextBlockBody } from '@dx-ui/osc-marketing';
import { useIntersectionObserver } from 'usehooks-ts';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { Link } from '@dx-ui/osc-link';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import type { TDynamicGridItem } from './dynamic-grid-item';
import { useRect } from '@dx-ui/utilities-use-rect';

type TDynamicGridItemWithoutListeners = Omit<TDynamicGridItem, 'onItemClick' | 'onClickLink'>;

export type TDynamicGridItemWOM = TDynamicGridItemWithoutListeners & {
  /** fade-in text animations */
  isAnimated?: boolean;
  /** theme for the component `dark` or `light` */
  brandComponentTheme?: CmsBrandComponentTheme;
};

export type TDynamicGridItemAndAspectRatiosWOM = TDynamicGridItemWOM & {
  /** The aspect ratio for the grid item images */
  imageAspectRatio: AspectRatio;
};

export const DocsTDynamicGridItemWOM: React.FC<
  React.PropsWithChildren<TDynamicGridItemAndAspectRatiosWOM>
> = () => null;

const DynamicGridItemWOM: React.FC<TDynamicGridItemAndAspectRatiosWOM> = (item) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  const {
    id,
    imageUrl,
    imageAltTxt,
    headline,
    link,
    captionData,
    brandComponentTheme,
    shortDescription,
    isAnimated,
  } = item;

  const ar = item?.imageAspectRatio;

  const { ref: intersectionRef } = useIntersectionObserver({
    threshold: [1],
    freezeOnceVisible: true,
  });
  const observedWrapper = !getIsReducedMotion() && isAnimated ? intersectionRef : null;
  const headlineId = `headline${id}`;
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <>
      {item ? (
        <div
          className={cx(
            'dynamic-grid-item-wom relative group pb-5 flex flex-col justify-between h-full',
            item.cmsTranslationClasses
          )}
          data-testid={`dynamicgrid-wom-item-${id}`}
          key={id}
        >
          <div className="flex flex-col">
            {headline && (
              <h3
                id={headlineId}
                data-testid={`dynamicgrid-wom-item-head-${id}`}
                data-osc-product="dynamic-grid-wom-item-headline"
                className={cx(
                  'dynamic-grid-item-wom text-text my-2 font-bold heading-lg lg:heading-xl',
                  'brand-es-refresh:heading-xl brand-es-refresh:lg:heading-2xl',
                  'brand-ey:heading-2xl brand-ey:lg:heading-3xl',
                  'brand-gu:heading-2xl brand-gu:lg:heading-3xl',
                  'brand-hp:heading-xl brand-hp:lg:heading-2xl brand-hp:py-4',
                  'brand-lx:font-normal brand-lx:heading-2xl brand-lx:lg:heading-3xl',
                  'brand-nd:heading-2xl brand-nd:lg:heading-3xl',
                  'brand-ol:font-serif brand-ol:not-italic',
                  'brand-ou:font-light brand-ou:heading-xl brand-ou:lg:heading-2xl',
                  'brand-qq:font-serif brand-qq:font-normal',
                  'brand-wa:font-normal brand-wa:uppercase',
                  {
                    '!text-text-inverse': isDark,
                    'brand-ht:!text-text-inverse': isLight,
                    'brand-es-refresh:text-primary brand-ou:text-primary brand-hp:text-primary':
                      !isDark,
                    'brand-hp:pt-4 brand-hp:pb-0': !shortDescription,
                  }
                )}
              >
                {headline}
              </h3>
            )}

            <div ref={ref} className="order-first">
              <ResponsiveImage
                id={`dynamic-grid-wom-item-img-${id}`}
                aspectRatio={ar}
                imageUrl={imageUrl}
                altText={imageAltTxt}
                width={rect?.width ?? 0}
                captionData={captionData}
                className="brand-gu:group-hover:scale-110 brand-gu:group-hover:opacity-80 duration-300 ease-in-out"
              />
            </div>
            <div
              className={cx(
                'gridWOM-bodyText text-text mb-2 sm:text-lg lg:text-xl',
                'brand-ol:text-lg',
                'brand-qq:text-lg',
                {
                  'text-text-inverse': isDark,
                  'brand-ht:!text-text-inverse': isLight,
                }
              )}
              ref={observedWrapper}
            >
              <div className="relative z-10 pb-4">
                <TextBlockBody brandComponentTheme={brandComponentTheme} className="!py-0">
                  {shortDescription || ''}
                </TextBlockBody>
              </div>
            </div>
          </div>

          {link?.label && link?.url ? (
            <Link
              className="brand-gu:text-base brand-gu:lg:text-lg text-lg lg:text-xl"
              anchorClassName={cx(
                "after:absolute after:inset-0 after:z-0 after:content-[''] text-primary inline-block underline decoration-2 hover:decoration-4 hover:text-primary-alt focus:text-primary-alt focus:decoration-4",
                'brand-gu:font-mono brand-gu:uppercase brand-gu:tracking-wider brand-gu:no-underline',
                'brand-hp:font-serif',
                'brand-ol:mt-3 brand-ol:mb-6',
                'brand-wa:font-normal brand-wa:uppercase brand-wa:tracking-[1px] brand-wa:underline brand-wa:underline-offset-8 brand-wa:decoration-2 brand-wa:bg-transparent brand-wa:border-none',
                {
                  '!text-text-inverse hover:!text-text-inverse': isDark,
                  'brand-ht:!text-text-inverse brand-ht:hover:!text-text-inverse': isLight,
                  'brand-hp:text-brand': !isDark,
                }
              )}
              isNewWindow={link?.isNewWindow || false}
              url={link?.url}
              showNewWindowIcon={link?.isNewWindow || false}
              underline={false}
              data-testid={`dynamicgrid-wom-item-link-${id}`}
            >
              <div className="relative inline">
                {link.label}
                <div
                  className={cx(
                    'brand-gu:block absolute hidden h-px w-full origin-bottom-left scale-x-100 duration-[250ms] ease-out group-hover:scale-x-0 group-hover:ease-out',
                    {
                      'brand-gu:bg-bg': isDark,
                      'brand-gu:bg-primary': !isDark,
                    }
                  )}
                />
              </div>
            </Link>
          ) : null}
          {item.cmsDocumentControl}
        </div>
      ) : null}
    </>
  );
};

export { DynamicGridItemWOM };
export default DynamicGridItemWOM;
