import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { useEventListener } from 'usehooks-ts';
import classnames from 'classnames';
import { ControlButton } from './video-player.control-button';
import { ControlIcon } from './video-player.control-icon';
import { SettingsMenu } from './video-player.settings-menu';
import type { useVideoPlayer } from './hooks/use-video-player';
import { getKeyDownNavigation } from '@dx-ui/utilities-accessibility';
import { getClasses } from './util/classes';

export type Track = {
  type: 'captionTrack' | 'transcriptTrack' | 'audioTrack' | (string & NonNullable<unknown>);
  url: string;
  label: string;
  language: string;
};

export type BaseVideoTrack = {
  videoGroup?: 'standard' | 'extended';
  videoLabel?: string;
  videoName?: string;
  videoUrl: string;
  captionTracks?: Track[];
  transcriptTracks?: Track[];
};

export type TrackOption = HTMLTrackElement;

export type VideoTrack = Omit<BaseVideoTrack, 'captionTracks' | 'transcriptTracks'> & {
  captionTracks: TrackOption[];
  transcriptTracks: TrackOption[];
};

type VideoButtonOptions = Partial<{
  isVisible: boolean;
}>;

export type VideoControlsProps = {
  /** Used to help with positioning of the controls */
  className?: string;
  /** For color theming */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Buttons options for controlling display */
  buttonOptions?: {
    mute?: VideoButtonOptions;
    play?: VideoButtonOptions;
    captions?: VideoButtonOptions;
    settings?: VideoButtonOptions;
  };
};

/**
 * A set of video controls for internal video sources that includes pause/play, mute/unmute, and closed captions.
 *
 * This should generally be used with the `useVideoPlayer` hook from `@dx-ui/osc-video-player`.
 * The hook provides the necessary state and functions to control the video player.
 * Consumers can spread the props from the videoControlsProps returned from the hook.
 *
 * Accepts className to add your own placement and styles to the component.
 */
export function VideoControls(
  props: ReturnType<typeof useVideoPlayer>['videoControlsProps'] & VideoControlsProps
) {
  const {
    className,
    brandComponentTheme,
    buttonOptions,
    controlsState,
    playerState,
    activeVideo,
    optionsState,
    toggleCaptions,
    toggleMuteButton,
    togglePlayButton,
    updateCaption,
    updateTranscript,
    updateAudioTrack,
  } = props;
  const { t } = useTranslation('osc-video-player');
  const listRef = useRef<React.ElementRef<'ul'>>(null);
  const showMuteButton = buttonOptions?.mute?.isVisible ?? true;
  const showPlayButton = buttonOptions?.play?.isVisible ?? true;
  const hasCaptions = (activeVideo?.captionTracks || []).length > 0;
  const hasMultipleCaptions = (activeVideo?.captionTracks || []).length > 1;
  const showCaptionsButton = (buttonOptions?.captions?.isVisible ?? true) && hasCaptions;
  const hasTranscripts = (activeVideo?.transcriptTracks || []).length > 0;
  const hasAudioDescriptions = optionsState.length > 1;
  const hasSettings = hasMultipleCaptions || hasTranscripts || hasAudioDescriptions;
  const showSettingsButton = (buttonOptions?.settings?.isVisible ?? true) && hasSettings;

  useEventListener(
    'keydown',
    (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        event.preventDefault();
        const elements = Array.from(listRef.current?.querySelectorAll('button') || []);
        const onKeyDownNavigation = getKeyDownNavigation({ elements });
        onKeyDownNavigation(event);
      }
    },
    listRef
  );

  return (
    <div className={className} data-testid="video-controls">
      <ul
        className={classnames(
          'bg-bg divide-border relative z-10 inline-flex divide-x shadow-md motion-safe:transition-opacity rtl:divide-x-reverse',
          getClasses({ brandComponentTheme }),
          {
            'opacity-100': controlsState.isVisible,
            'opacity-0': !controlsState.isVisible,
          }
        )}
        data-testid="testBackgroundCss"
        ref={listRef}
      >
        {showPlayButton ? (
          <ControlButton
            aria-label={playerState.isPlaying ? t('pause') : t('play')}
            brandComponentTheme={brandComponentTheme}
            onClick={togglePlayButton}
            iconContent={
              <>
                <ControlIcon
                  brandComponentTheme={brandComponentTheme}
                  className={classnames({ hidden: !playerState.isPlaying })}
                  name="pause"
                />
                <ControlIcon
                  brandComponentTheme={brandComponentTheme}
                  className={classnames({ hidden: playerState.isPlaying })}
                  name="play"
                />
              </>
            }
          />
        ) : null}
        {showMuteButton ? (
          <ControlButton
            aria-label={playerState.isMuted ? t('unmute') : t('mute')}
            brandComponentTheme={brandComponentTheme}
            onClick={toggleMuteButton}
            iconContent={
              <>
                <ControlIcon
                  brandComponentTheme={brandComponentTheme}
                  className={classnames({ hidden: !playerState.isMuted })}
                  name="sound-off"
                />
                <ControlIcon
                  brandComponentTheme={brandComponentTheme}
                  className={classnames({ hidden: playerState.isMuted })}
                  name="sound-on"
                />
              </>
            }
          />
        ) : null}
        {showCaptionsButton ? (
          <ControlButton
            aria-label={t('captions')}
            aria-pressed={Boolean(playerState.activeCaptionTrack)}
            brandComponentTheme={brandComponentTheme}
            onClick={() => {
              const currentTrack = playerState.activeCaptionTrack;
              const firstTrack = activeVideo?.captionTracks?.[0] as HTMLTrackElement;
              currentTrack ? toggleCaptions() : updateCaption(firstTrack);
            }}
            iconContent={
              <>
                <ControlIcon
                  brandComponentTheme={brandComponentTheme}
                  className={classnames({ hidden: !playerState.showCaptions })}
                  variant="solid"
                  name="closed-captions"
                />
                <ControlIcon
                  brandComponentTheme={brandComponentTheme}
                  className={classnames({ hidden: playerState.showCaptions })}
                  name="closed-captions"
                />
              </>
            }
          />
        ) : null}
        {showSettingsButton ? (
          <SettingsMenu
            brandComponentTheme={brandComponentTheme}
            activeVideo={activeVideo}
            optionsState={optionsState}
            playerState={playerState}
            updateAudioTrack={updateAudioTrack}
            updateTranscript={updateTranscript}
            updateCaption={updateCaption}
          />
        ) : null}
      </ul>
    </div>
  );
}
