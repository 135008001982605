import cx from 'classnames';
import { DynamicGridWOM, DynamicGrid } from '@dx-ui/osc-dynamic-grids';

import { extractInstanceIndexFromId } from '../../utils/id-transformer';
import {
  createCpmComponentDefinition,
  useCreateManageContentButton,
  selectFirstAssetWithAspectRatio,
  selectLastAssetWithAspectRatio,
} from '@dx-ui/cpm-sdk';
import { useSegmentedItems } from '../../hooks/use-segments';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';

export default createCpmComponentDefinition(
  '4X Grid',

  function mapData({ data }) {
    const { link } = data;
    const asset = selectFirstAssetWithAspectRatio('3x4', data.cpmAssets);
    const modalImageUrl = selectLastAssetWithAspectRatio('16x9', data.cpmAssets)?.aspectRatios[
      '16x9'
    ].url;

    return {
      $ref: data.ref?.$ref,
      id: data.id ?? '',
      captionData: asset?.caption
        ? {
            caption: asset?.caption ?? '',
          }
        : undefined,
      imageUrl: asset?.aspectRatios['3x4']?.url ?? '',
      imageAltTxt: asset?.altText ?? '',
      headline: data.headline,
      shortDescription: data.shortDescription,
      link: link?.url ? link : undefined,
      segmentIds: data.segmentIds,
      itemTitle: data?.label ?? '',
      modalImageUrl,
      cmsTranslationClasses: data.cmsTranslationClasses,
    };
  },

  function FourXGrid({ componentParams, items = [], listData, mappedPage, metrics }) {
    const filteredItems = useSegmentedItems(items, { maintainUnsegmentedCount: true });
    const createManageContentButton = useCreateManageContentButton();
    const { display } = componentParams;
    const isWA = mappedPage.brandCode === 'WA';

    if (!listData) {
      return null;
    }

    const { id, headline, description, links } = listData;

    if (!filteredItems.length) {
      return null;
    }

    const itemsWithManageContentButton = filteredItems.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    const onViewItem = (selectedItemId: string) => {
      const index = filteredItems.findIndex((item) => item.id === selectedItemId);
      const item = filteredItems[index];

      metrics.trackGridItemClick?.([
        {
          itemNumber: index + 1,
          totalItems: filteredItems.length,
          label: item?.itemTitle ?? '',
          gridNumber: extractInstanceIndexFromId(id),
          is4XGrid: true,
        },
      ]);
    };

    const listLink = links[0];
    const isThemeAdded = Boolean(componentParams.theme);

    if (display === 'wom') {
      return (
        <BrandComponentThemeInline
          componentClassName="fourXWOMGrid"
          componentParams={componentParams}
          brandCode={mappedPage.brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <div
            className={cx('gridWrapper', {
              'py-12': isThemeAdded,
              'py-6': !isThemeAdded,
            })}
          >
            <DynamicGridWOM
              imageAspectRatio="3:4"
              isAnimated={componentParams.animation}
              listHeadline={headline ?? undefined}
              listDescription={description ?? undefined}
              id={id}
              link={listLink ? listLink : undefined}
              items={itemsWithManageContentButton}
              brandComponentTheme={componentParams.theme}
            />
          </div>
        </BrandComponentThemeInline>
      );
    } else {
      return (
        <BrandComponentThemeInline
          componentClassName="fourXGrid"
          componentParams={componentParams}
          brandCode={mappedPage.brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <DynamicGrid
            listDescription={description ?? undefined}
            listHeadline={headline ?? undefined}
            id={id}
            imageAspectRatio="3:4"
            items={itemsWithManageContentButton}
            onViewItem={onViewItem}
            brandComponentTheme={componentParams.theme}
            themeWrapperProps={{ brandCodeForTheme: mappedPage.brandCode }}
            isAnimated={isWA}
          />
        </BrandComponentThemeInline>
      );
    }
  }
);
