import { GeneratedQueries } from '@dx-ui/cpm-sdk';
import type { PlacesAutocompleteSuggestion } from '@dx-ui/framework-places-autocomplete';
import { useIsClient } from 'usehooks-ts';

import { useMemo } from 'react';

export type PopularDestinationSuggestion = PlacesAutocompleteSuggestion[] | undefined;

export const SEARCH_DRAW_BRIDGE = 'SearchDrawbridge';
export const CONFIG_POPULAR_DESTINATION = 'config-popular-destinations';

type RecentSearchesPopularDestinationConfigProp = {
  isPopularDestinations?: boolean;
  isRecentSearches?: boolean;
  country?: string;
};

const { useTogglesQuery } = GeneratedQueries;

export function useRecentSearchPopularDestinationData({
  isPopularDestinations = true,
  isRecentSearches = true,
  country,
}: RecentSearchesPopularDestinationConfigProp) {
  const isClient = useIsClient();
  const { data, isLoading } = useTogglesQuery(
    {
      configs: [CONFIG_POPULAR_DESTINATION],
      features: [CONFIG_POPULAR_DESTINATION, SEARCH_DRAW_BRIDGE],
    },
    { enabled: (isPopularDestinations || isRecentSearches) && isClient }
  );

  const recentSearchAndPopularDestinationData = useMemo(() => {
    if (isLoading) {
      return { showRecentSearches: false };
    }
    const isDrawbridgeEnabled = !!data?.featureToggles?.find(
      (toggle) => toggle.name === SEARCH_DRAW_BRIDGE
    )?.enabled;
    const isPopularDestinationEnabled = data?.featureToggles?.find(
      (toggle) => toggle.name === CONFIG_POPULAR_DESTINATION
    )?.enabled;
    const popularDestination = isPopularDestinationEnabled
      ? !!country
        ? COUNTRY_BASED_POPULAR_DESTINATIONS[country]
        : data?.configToggles?.find((config) => config.name === CONFIG_POPULAR_DESTINATION)?.config
      : null;

    return {
      showRecentSearches: !isDrawbridgeEnabled,
      popularDestinationOptions: popularDestination as PopularDestinationSuggestion,
    };
  }, [data, isLoading, country]);

  return recentSearchAndPopularDestinationData;
}

/********** NHCSEARCH-5194 A/B Test - popular destinations based on user location Data ********/

const US_POPULAR_DESTINATIONS = [
  {
    description: 'Las Vegas, Nevada, US',
    placeId: 'dx-location::locality::us::nv::las-vegas',
    formattedSuggestion: {
      mainText: 'Las Vegas',
      secondaryText: 'Nevada, US',
    },
  },
  {
    description: 'New York, New York, US',
    placeId: 'dx-location::locality::us::ny::new-york-city',
    formattedSuggestion: {
      mainText: 'New York',
      secondaryText: 'New York, US',
    },
  },
  {
    description: 'Orlando, Florida, US',
    placeId: 'dx-location::locality::us::fl::orlando',
    formattedSuggestion: {
      mainText: 'Orlando',
      secondaryText: 'Florida, US',
    },
  },
  {
    description: 'Chicago, Illinois, US',
    placeId: 'dx-location::locality::us::il::chicago',
    formattedSuggestion: {
      mainText: 'Chicago',
      secondaryText: 'Illinois, US',
    },
  },
  {
    description: 'Miami, Florida, US',
    placeId: 'dx-location::locality::us::fl::miami',
    formattedSuggestion: {
      mainText: 'Miami',
      secondaryText: 'Florida, US',
    },
  },
];

const CA_POPULAR_DESTINATIONS = [
  {
    description: 'Toronto, Ontario, CA',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Toronto',
      secondaryText: 'Ontario, CA',
    },
  },
  {
    description: 'Niagara Falls, Ontario, CA',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Niagara Falls',
      secondaryText: 'Ontario, CA',
    },
  },
  {
    description: 'Montreal, Quebec, CA',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Montreal',
      secondaryText: 'Quebec, CA',
    },
  },
  {
    description: 'Vancouver, British Columbia, CA',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Vancouver',
      secondaryText: 'British Columbia, CA',
    },
  },
  {
    description: 'Ottawa, Ontario, CA',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Ottawa',
      secondaryText: 'Ontario, CA',
    },
  },
];

const UK_POPULAR_DESTINATIONS = [
  {
    description: 'London, England, GB',
    placeId: '',
    formattedSuggestion: {
      mainText: 'London',
      secondaryText: 'England, GB',
    },
  },
  {
    description: 'Edinburgh Scotland, GB',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Edinburgh',
      secondaryText: 'Scotland, GB',
    },
  },
  {
    description: 'Glasgow, Scotland, GB',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Glasgow',
      secondaryText: 'Scotland, GB',
    },
  },
  {
    description: 'Birmingham, England, GB',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Birmingham',
      secondaryText: 'England, GB',
    },
  },
  {
    description: 'Dubai, United Arab Emirates',
    placeId: '',
    formattedSuggestion: {
      mainText: 'Dubai',
      secondaryText: 'United Arab Emirates',
    },
  },
];

type CountryBasedPopularDestinationsSuggestions =
  | Pick<PlacesAutocompleteSuggestion, 'description' | 'placeId' | 'formattedSuggestion'>[]
  | undefined;

const COUNTRY_BASED_POPULAR_DESTINATIONS: Record<
  string,
  CountryBasedPopularDestinationsSuggestions
> = {
  CA: CA_POPULAR_DESTINATIONS,
  GB: UK_POPULAR_DESTINATIONS,
  US: US_POPULAR_DESTINATIONS,
};
