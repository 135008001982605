import type { QueryKey } from '@tanstack/react-query';
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type { BrProps } from '@bloomreach/react-sdk';
import type { Page, ContainerItem } from '@bloomreach/spa-sdk';
import { ErrorBoundary as OSCErrorBoundary } from '@dx-ui/osc-error-boundary';
import { BrandButton } from '@dx-ui/osc-brand-buttons';
import { Spinner } from '@dx-ui/osc-spinner';
import * as React from 'react';

import type { BrPageModel, DocumentModels } from '../adapters/types';
import { CpmMergedBrPageContextProvider } from '../context/CpmMergedBrPageContext';
import type { MappedComponentName } from '../schema';

const MAX_TEXT_SAMPLE_SIZE = 1000;

export function findLastImageName(text: string) {
  const sampleSize = text.length < MAX_TEXT_SAMPLE_SIZE ? text.length : MAX_TEXT_SAMPLE_SIZE;
  const sample = text.slice(-sampleSize);
  const lastMatch = [...sample.matchAll(/"name":"([^"]*)"/g)].pop();

  if (Array.isArray(lastMatch) && lastMatch.length > 1) {
    return lastMatch[1];
  }
}

export function makeDefaultQueryFetcherFn(token?: string) {
  return async function defaultQueryFn({ queryKey }: { queryKey: QueryKey }) {
    const [link] = queryKey;

    const url = new URL(link as string);
    const params = new URLSearchParams(url.search);

    // Remove the ref level param so the CMS returns all the information
    params.delete('_maxreflevel');

    url.search = params.toString();

    // Auth header required to see content that isn't published in the editor
    const headers = new Headers();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    const req = await fetch(url, {
      headers,
      credentials: 'include',
    });

    // Get the text first, in case the JSON response is invalid
    const text = await req.text();

    try {
      return JSON.parse(text);
    } catch (err) {
      if (err instanceof SyntaxError) {
        // Most common error is a broken image which causes the server to send a bad JSON object
        const imageName = findLastImageName(text);

        if (imageName) {
          throw new Error(
            `Error parsing JSON response. "${imageName}" is probably the cause, please re-browse the image.`,
            { cause: err }
          );
        }
      }

      throw err;
    }
  };
}

export function CpmQueryClientProvider({
  children,
  token,
  queryFn = makeDefaultQueryFetcherFn,
}: {
  children: React.ReactNode;
  token?: string;
  queryFn?: typeof makeDefaultQueryFetcherFn;
}) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            queryFn: queryFn(token),
          },
        },
      })
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

/**
 * Determine if a component needs to fetch additional data before it can render
 */
export function shouldFetchContent(page: Page, models?: DocumentModels) {
  if (!models) {
    return false;
  }

  return Object.values(models).some((ref) => {
    const content = page.getContent(ref?.$ref ?? '');

    if (!content || typeof content.getData !== 'function') {
      return false;
    }
    const data = content.getData();

    const shouldFetchImages =
      Array.isArray(data?.images) && data.images.some((image) => !!image?.imageRef?.uuid);
    const shouldFetchListItems =
      Array.isArray(data?.itemsRef) && data.itemsRef.some((itemRef) => !!itemRef?.uuid);
    const shouldFetchRelatedDocuments =
      Array.isArray(data?.relatedDocumentsRef) &&
      data.relatedDocumentsRef.some((ref) => !!ref?.uuid);

    return shouldFetchImages || shouldFetchListItems || shouldFetchRelatedDocuments;
  });
}

export function CpmDataWrapper({
  component: brComponent,
  page: brPage,
  children,
  componentName,
}: BrProps<ContainerItem> & {
  children: React.ReactNode;
  componentName: MappedComponentName;
}) {
  if (!brComponent) {
    return (
      <ErrorBanner>
        Missing Bloomreach component (No <pre>BrContainerItem</pre>)
      </ErrorBanner>
    );
  }

  if (!brPage) {
    return (
      <ErrorBanner>
        Missing Bloomreach page (No <pre>BrPage</pre>)
      </ErrorBanner>
    );
  }

  const shouldFetch = shouldFetchContent(brPage, brComponent.getModels());

  if (!shouldFetch) {
    return children;
  }

  const url = brComponent.getUrl();

  if (!url) {
    return <ErrorBanner>Component has no URL to fetch data</ErrorBanner>;
  }

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <OSCErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorBanner>
              <p className="text-2xl">There was an error loading data for {componentName}!</p>
              <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
              <BrandButton label="Try again" onClick={() => resetErrorBoundary()} />
            </ErrorBanner>
          )}
        >
          <React.Suspense
            fallback={
              <InfoBanner>
                <p className="text-2xl">Loading data for {componentName}</p>
                <Spinner size="md" />
              </InfoBanner>
            }
          >
            <FetchComponentData brPage={brPage} queryUrl={url}>
              {children}
            </FetchComponentData>
          </React.Suspense>
        </OSCErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

function FetchComponentData({
  brPage,
  children,
  queryUrl,
}: {
  brPage: Page;
  children: React.ReactNode;
  queryUrl: string;
}) {
  const { data } = useSuspenseQuery<BrPageModel>({
    queryKey: [queryUrl],
  });

  React.useEffect(() => {
    if (data) {
      brPage.sync();
    }
  }, [brPage, data]);

  return <CpmMergedBrPageContextProvider page={data}>{children}</CpmMergedBrPageContextProvider>;
}

function InfoBanner({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-info-alt border-border-alt flex flex-col items-center justify-center space-y-4 border p-8 text-center">
      {children}
    </div>
  );
}

function ErrorBanner({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-danger-alt border-danger flex flex-col items-center justify-center space-y-4 border p-8 text-center">
      {children}
    </div>
  );
}
