import Head from 'next/head';
import { useRouter } from 'next/router';
import type { EnvironmentVariables } from '@dx-ui/cpm-mapping-shared';
import { LANGUAGES, facebookAppId, getAppVersion } from '../utils/constants';
import { isBrandHomePageUrlFormat } from '../utils/routing';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';

import type { TSocialMediaLinks } from '../types/gql';

const HOMEPAGE_IN_ALL_LANGUAGES = Object.values(LANGUAGES).map((lang) => `/${lang}/`);

export type TPageHead = {
  brandLogoImageSrc?: string;
  metaDescription?: string;
  metaKeywords?: string;
  hideSearchEngine?: boolean;
  pageTitle: string;
  schemaName?: string;
  schemaUrl?: string;
  localeCode?: string;
  baseAppUrl?: string;
  brandFaviconImgSrc?: string;
  fullBrandPath?: string;
  seoImage?: string;
  seoArticleTitle?: string;
  brandCode?: string;
  socialMediaLinks?: TSocialMediaLinks;
  phoneNumber?: string;
  isEdgeTestActive?: boolean;
  env: EnvironmentVariables;
  supportedLanguages?: SupportedLanguage[];
};

export const CustomHead = ({
  brandLogoImageSrc,
  hideSearchEngine = false,
  metaDescription,
  metaKeywords,
  pageTitle,
  schemaName,
  baseAppUrl = '',
  localeCode = '',
  brandFaviconImgSrc,
  seoImage,
  seoArticleTitle,
  brandCode,
  socialMediaLinks,
  phoneNumber,
  isEdgeTestActive,
  env,
  supportedLanguages,
}: TPageHead) => {
  const router = useRouter();
  const url = new URL(router.asPath, env.OHW_BASE_URL);

  const pathname = baseAppUrl ? new URL(baseAppUrl).pathname : null;
  const origin = baseAppUrl ? new URL(baseAppUrl).origin : null;

  const isBrandHomePage =
    pathname && brandCode ? isBrandHomePageUrlFormat(pathname, brandCode) : false;

  const isPortfolioHomePage = pathname ? HOMEPAGE_IN_ALL_LANGUAGES.includes(pathname) : false;

  let seoSchema = null;

  const brandSlug = Array.isArray(router.query.brandSlug) ? router.query.brandSlug.join('/') : null;

  let canonicalUrl = origin && localeCode ? `${origin}/${localeCode}/` : null;

  if (canonicalUrl && brandSlug) {
    canonicalUrl = canonicalUrl + brandSlug + '/';
  }

  if (canonicalUrl) {
    if (seoArticleTitle) {
      seoSchema = {
        '@context': 'http://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': canonicalUrl,
        },
        headline: seoArticleTitle,
        ...(seoImage && { image: seoImage }),
        publisher: {
          '@type': 'Organization',
          name: 'Hilton',
          logo: {
            '@type': 'ImageObject',
            url: 'https://www.hilton.com/modules/assets/svgs/logos/shop/WW.svg',
          },
        },
      };
    } else if (isBrandHomePage) {
      seoSchema = {
        '@context': 'http://schema.org',
        '@type': 'Brand',
        ...(schemaName && { name: schemaName }),
        ...(brandLogoImageSrc && { logo: brandLogoImageSrc }),
        ...(metaDescription && { description: metaDescription }),
        ...(canonicalUrl && { url: canonicalUrl }),
        ...(socialMediaLinks && { sameAs: socialMediaLinks.map((media) => media.url) }),
      };
    } else if (isPortfolioHomePage) {
      seoSchema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Hilton',
        ...(brandLogoImageSrc && { logo: brandLogoImageSrc }),
        ...(metaDescription && { description: metaDescription }),
        telephone: phoneNumber || '+1-800-445-8667',
        ...(canonicalUrl && { url: canonicalUrl }),
        sameAs: [
          ...(socialMediaLinks ? socialMediaLinks.map((media) => media.url) : []),
          'https://en.wikipedia.org/wiki/Hilton_Worldwide',
        ],
      };
    } else {
      seoSchema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        ...(schemaName && { name: schemaName }),
        ...(canonicalUrl && { url: canonicalUrl }),
      };
    }
  }

  const ogImageSrc = seoImage || brandLogoImageSrc;

  return (
    <Head>
      <title>{pageTitle}</title>
      {brandFaviconImgSrc ? <link rel="icon" href={brandFaviconImgSrc} /> : null}
      {hideSearchEngine ? <meta name="robots" content="noindex,follow" /> : null}
      <meta name="dx-ui-service" content="hotels-ui" />
      <meta name="dx-version" content={getAppVersion(env)} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@Hilton" />
      <meta name="og:title" content={seoArticleTitle ?? pageTitle} />
      <meta name="og:site_name" content="Hilton" />
      <meta name="og:type" content={seoArticleTitle ? 'article' : 'website'} />
      {isEdgeTestActive ? <meta name="dx-ui-service" content="ConductricsEdgeEnabled" /> : null}
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {supportedLanguages?.length ? (
        <>
          {supportedLanguages.includes('en') ? (
            <link
              rel="alternate"
              hrefLang="x-default"
              href={formatHref('en', url.pathname, env.OHW_BASE_URL)}
              key="x-default"
            />
          ) : null}
          {supportedLanguages.map((language) => (
            <link
              rel="alternate"
              hrefLang={language}
              href={formatHref(language, url.pathname, env.OHW_BASE_URL)}
              key={`alternate-link-${language}`}
            />
          ))}
        </>
      ) : null}
      {metaDescription ? <meta name="description" content={metaDescription} /> : null}
      {metaKeywords || metaKeywords === '' ? <meta name="keywords" content={metaKeywords} /> : null}
      {metaDescription ? <meta name="og:description" content={metaDescription} /> : null}
      {ogImageSrc ? <meta name="og:image" content={ogImageSrc} /> : null}
      {canonicalUrl ? <meta name="og:url" content={canonicalUrl} /> : null}
      {facebookAppId ? <meta name="fb:app_id" content={facebookAppId} /> : null}
      {seoSchema ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(seoSchema) }}
        />
      ) : null}
    </Head>
  );
};

function formatHref(locale: string, pathname: string, base?: string) {
  const url = new URL(`/${locale}${pathname}`, base || 'https://www.hilton.com/');
  return `${url.origin}${url.pathname}`;
}
